import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import API from '../../../../../../Services/API'
import BreadCrumbs from '../../../../../../Components/Widgets/BreadCrumbs/BreadCrumbs'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Heading, Card, CardBody, Box, Image, Text, Input, Textarea, Button, FormLabel, Select} from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import BreadCrumbsNav from '../../../../../../Components/Shared/Navbar/BreadCrumbsNav';
import { EditContext } from '../../../../../../Context/BreadcrumbsContext';
import { CardMedia } from '@mui/material';
import UnifiedFileViewer from './UnifiedFileViewer';
import ErrorWidget from '../../../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const ViewArticle = () => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(false)
    const [editable, setEditable] = useState();
    const [viewArticle, setViewArticle] = useState()
    
    const [message, setMessage] = useState(null)
    const {id} = useParams();
    const editor = useRef(null);
    const [file, setFile] = useState(null);
    const {edit, setEdit } = useContext(EditContext);
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        setIsLoading(true)
        API.get(`/guides?id=${id}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            setViewArticle(response.data[0])
            setIsLoading(false)
          }).catch(error=>{
            setIsLoading(false)
          })
        },[id])
        
        console.log(
          "response", viewArticle
         );
    // console.log("guidesdetails",viewArticle)
    const updateArticle = () =>{
      setMessage(null)
      setIsLoading(true)
      const formData = new FormData()
      if(file != null){
        formData.append('resource', $("#resource")[0].files[0]); // Resource file (if any)
        formData.append('file_choices', $("#file_choices").val()); // File type (document or video)
      }
      
      formData.append('id', parseInt(id))
      formData.append(
        "name",
        $("#name").val()
          ? $("#name").val()
          : viewArticle
          ? viewArticle.name
          : ""
      );
      formData.append(
        "note",
        $("#note").val()
          ? $("#note").val()
          : viewArticle
          ? viewArticle.note
          : ""
      );

      API.put(`/guides`, formData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      .then((response) => {
        setViewArticle(response.data[0])
        setMessage("Training guide/video has been edited successfully.")
              
            API.get(`/guides?id=${id}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
              setMessage("Training guide/video has been edited successfully.")
              
              setViewArticle(response.data[0])
            }).catch(error=>{
                setIsLoading(false)
            })
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    const geteditStatus = (isEdit) =>{
        setEditable(isEdit)
    }

    function bannerUpload(e) {
        setFile(e.target.files[0]);
    }

    return (
      <>
        {isLoading ? <LoaderSpinner /> : null}
        {message && message ? <ErrorWidget message={message && message} /> : null}
     
        <BreadCrumbs
          geteditStatus={geteditStatus}
          title={viewArticle && viewArticle.name}
        />
        <Card>
          <CardBody>
            <Box>
              {edit ? (
                <>
                  <FormLabel>Name</FormLabel>
                  <Input
                    id={"name"}
                    placeholder="medium size"
                    defaultValue={viewArticle && viewArticle.name}
                    size="md"
                    mb={"15px"}
                  />
                  <FormLabel>Type</FormLabel>
                  <Select defaultValue={ viewArticle && viewArticle?.file_choices} id="file_choices" mb="15px" placeholder="Select type">
                    <option value="document">Document</option>
                    <option value="video">Video</option>
                  </Select>
                  <FormLabel>Resource</FormLabel>
                  <UnifiedFileViewer
                    fileType={viewArticle?.file_choices}
                    fileUrl={viewArticle?.resource}
                  />
                  <Input
                    id={"resource"}
                    type="file"
                    onChange={bannerUpload}
                    mb={"15px"}
                    padding={"4px"}
                  />
                  <FormLabel>Note</FormLabel>
                  <JoditEditor
                    id={"note"}
                    ref={editor}
                    value={viewArticle && viewArticle.note}
                    tabIndex={1} // tabIndex of textarea
                    onChange={(newContent) => {}}
                  />
                  <Button
                    className="buttonPrimary"
                    mt={"15px"}
                    onClick={updateArticle}
                  >
                    {" "}
                    Update Article{" "}
                  </Button>
                </>
              ) : (
                <>
                  <Heading as="h2" size="lg" mb={"25px"}>
                    {viewArticle && viewArticle.name}
                  </Heading>
                  <Text mt={"25px"}>
                    {viewArticle && viewArticle.note != null
                      ? parse(viewArticle && viewArticle.note)
                      : ""}
                  </Text>
                  <UnifiedFileViewer
                    fileType={viewArticle?.file_choices}
                    fileUrl={viewArticle?.resource}
                    url={viewArticle?.url}
                  />
                </>
              )}
            </Box>
          </CardBody>
        </Card>
      </>
    );
}

export default ViewArticle