import React, { useEffect, useState } from 'react';
import API from '../../../../Services/API';
import Styles from '../notifications.module.css'
import { Box, TableContainer, Table, Thead, Tbody, Tr, Th, Td, IconButton, VStack } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import DeleteComponent from './DeleteComponent/DeleteComponent'
import ViewNotification from '../ViewNotification/ViewNotification';

const NotificationInbox = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [notifications, setNotifications] =  useState([])
    const [sortOrder, setSortOrder] = useState('asc')
    const [sortColumn, setSortColumn] = useState('name')

    useEffect(() => {
        getNotification()
    }, []);
    const data = [
        { name: 'Alice', age: 25, country: 'USA' },
        { name: 'Bob', age: 30, country: 'Canada' },
        { name: 'Charlie', age: 35, country: 'UK' },
        { name: 'David', age: 40, country: 'Australia' },
    ];

    const handleSort = (column) => {
        const isAsc = sortColumn === column && sortOrder === 'asc';
        setSortColumn(column);
        setSortOrder(isAsc ? 'desc' : 'asc');
    };

    const sortedData = [...notifications].sort((a, b) => {
        if (typeof a[sortColumn] === 'number' && typeof b[sortColumn] === 'number') {
            return sortOrder === 'asc' ? a[sortColumn] - b[sortColumn] : b[sortColumn] - a[sortColumn];
        }
        if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        return date.toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: true,
        });
    };

    const getNotification = ()=>{
        API.get(`/custom-notification?query=received`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const sortedNotifications = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            setNotifications(sortedNotifications)
            props.getNotificationCount(response?.data?.filter(item => !item.notificaion_readed).length)
        }).catch(error=>{
        })
    }

    return (
        <>
            <Box maxHeight="600px" overflowY="auto" borderRadius="md" width={'100%'}>
                <TableContainer>
                    <Table variant="striped" >
                        <Thead>
                            <Tr>
                                <Th>
                                    Date Received
                                    <VStack spacing={0} align="center" float={'right'}>
                                        <IconButton
                                            className={Styles.arrowButtons}
                                            aria-label="Sort by name ascending"
                                            backgroundColor={'transparent'}
                                            icon={<ChevronUpIcon />}
                                            size="sm"
                                            onClick={() => handleSort('name')}
                                        />
                                        <IconButton
                                            className={Styles.arrowButtons}
                                            aria-label="Sort by name descending"
                                            backgroundColor={'transparent'}
                                            icon={<ChevronDownIcon />}
                                            size="sm"
                                            onClick={() => handleSort('name')}
                                        />
                                    </VStack>
                                </Th>
                                <Th>
                                    Subject
                                    <VStack spacing={0} align="center" float={'right'}>
                                        <IconButton
                                            className={Styles.arrowButtons}
                                            aria-label="Sort by age ascending"
                                            backgroundColor={'transparent'}
                                            icon={<ChevronUpIcon />}
                                            size="sm"
                                            onClick={() => handleSort('age')}

                                        />
                                        <IconButton
                                            className={Styles.arrowButtons}
                                            aria-label="Sort by age descending"
                                            backgroundColor={'transparent'}
                                            icon={<ChevronDownIcon />}
                                            size="sm"
                                            onClick={() => handleSort('age')}
                                        />
                                    </VStack>
                                </Th>
                                <Th>
                                    Status
                                    <VStack spacing={0} align="center" float={'right'}>
                                        <IconButton
                                            className={Styles.arrowButtons}
                                            aria-label="Sort by country ascending"
                                            backgroundColor={'transparent'}
                                            icon={<ChevronUpIcon />}
                                            size="sm"
                                            onClick={() => handleSort('country')}
                                        />
                                        <IconButton
                                            className={Styles.arrowButtons}
                                            aria-label="Sort by country descending"
                                            backgroundColor={'transparent'}
                                            icon={<ChevronDownIcon />}
                                            size="sm"
                                            onClick={() => handleSort('country')}
                                        />
                                    </VStack>
                                </Th>
                                <Th>
                                    Action
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sortedData.map((row, index) => (
                                <Tr key={index}>
                                    <Td>{formatDate(row.notf_delivry_tm)}</Td>
                                    <Td>
                                        <ViewNotification 
                                            subject={row.subject}
                                            id={row.id}
                                            message={row.msg}
                                            notificaionReaded={row.notificaion_readed}
                                            getNotification={getNotification}
                                        />
                                    </Td>
                                    <Td>
                                        {
                                            row.notificaion_readed ? 
                                                <>
                                                    <span
                                                        style={{
                                                            height: "7px",
                                                            width: "7px",
                                                            backgroundColor: "#E2E8F0",
                                                            borderRadius: "50%",
                                                            display: "inline-block",
                                                            marginRight: "0",
                                                            position: 'relative',
                                                            top: '-3px'
                                                        }}
                                                    ></span> Read
                                                </>
                                            : 
                                            <>
                                                <span
                                                    style={{
                                                        height: "7px",
                                                        width: "7px",
                                                        backgroundColor: "red",
                                                        borderRadius: "50%",
                                                        display: "inline-block",
                                                        marginRight: "0",
                                                        position: 'relative',
                                                        top: '-3px'
                                                    }}
                                                ></span> Unread
                                            </>
                                            
                                        }
                                    </Td>
                                    <Td className={Styles.actionButton}> 
                                        <DeleteComponent getNotification={getNotification} id={row.id} /> 
                                    </Td>
                                </Tr>
                            ))}
                            {
                                sortedData.length < 1 ?
                                <Tr backgroundColor={'#f9f9f9'}>
                                    <Td colSpan={'4'}> There are currently no notifications to show. </Td>
                                </Tr> : null
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    );
};

export default NotificationInbox;
