import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useDisclosure,
} from '@chakra-ui/react';
import LoaderSpinner from '../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Widgets/ErrorWidget/ErrorWidget';
import CreateMetricData from './CreateMetricData/CreateMetricData';
import EditMetricData from './EditMetricData/EditMetricData';
import DeleteMetric from './DeleteMetric/DeleteMetric';
import DataFilter from './DataFilter/DataFilter';
import NumberFormat from '../../../../Modules/NumberFormat/NumberFormat';
import API from '../../../../../Services/API';

const MetricData = ({ 
    metricData, 
    editable, 
    permissionType, 
    title, 
    filteredMetricData, 
    getUpdatedMetricData 
}) => {
    const token = localStorage.getItem('accessToken');
    const { metricId } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(true);
    const [deleteMessage, setDeleteMessage] = useState(false);
    const [allMetrics, setAllMetrics] = useState([]);
    const [fiscalYearDetail, setFiscalYearDetail] = useState(null);
    const [fiscalYearQtrDetail, setFiscalYearQtrDetail] = useState(null);
    const [countries, setCountries] = useState([]);
    const [strategy, setStrategy] = useState([]);

    // Fetch data on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [metrics, fiscalYear, countries, strategies] = await Promise.all([
                    API.get('/metric', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/fiscal-year-detail', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/country-list', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/strategy', { headers: { Authorization: `Bearer ${token}` } }),
                ]);

                setAllMetrics(metrics.data);
                setFiscalYearDetail(fiscalYear.data[0]?.fiscalyear);
                setFiscalYearQtrDetail(fiscalYear.data[1]?.fiscalquarter);
                setCountries(countries.data);
                setStrategy(strategies.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [token]);

    // Handle delete confirmation message
    const handleDeleteMessage = () => {
        setDeleteMessage(true);
        setTimeout(() => setDeleteMessage(false), 10000);
    };

    // Render table rows
    const renderTableRows = () => {
        return metricData?.map((metridata) => (
            <Tr key={metridata.id}>
                <Td textTransform="capitalize">{metridata.metric_status.replace(/-/g, ' ')}</Td>
                <Td textTransform="capitalize">
                    <Box width="150px" whiteSpace="pre-wrap">{metridata.strategy_name}</Box>
                </Td>
                <Td textTransform="capitalize">
                    <Box width="150px" whiteSpace="pre-wrap">{metridata.metric_name}</Box>
                </Td>
                <Td>
                    {metridata.datatype === 'money' && '$ '}
                    <NumberFormat number={metridata.value} />
                    {metridata.datatype === 'percent' && ' %'}
                </Td>
                <Td>
                    <Box width="100px" whiteSpace="pre-wrap">{metridata.fiscal_year_name}</Box>
                </Td>
                <Td>{metridata.fiscal_quarter_name}</Td>
                <Td>
                    <Box width="100px" whiteSpace="pre-wrap">{metridata.geography_name}</Box>
                </Td>
                <Td>{metridata.approval_status}</Td>
                {permissionType !== 'viewer' && (
                    <Td>
                        <Box width="150px" whiteSpace="pre-wrap">
                            <EditMetricData
                                metricName={metridata.metric_name}
                                period={metridata.fiscal_year}
                                fyq={metridata.fiscal_quarter}
                                periodName={metridata.fiscal_year_name}
                                fyqName={metridata.fiscal_quarter_name}
                                metricData={allMetrics}
                                fiscalYearDetail={fiscalYearDetail}
                                fiscalYearQtrDetail={fiscalYearQtrDetail}
                                value={metridata.value}
                                geography={metridata.geography}
                                geographyName={metridata.geography_name}
                                approvalStatus={metridata.approval_status}
                                status={metridata.metric_status}
                                id={metridata.id}
                                getUpdatedPending={() => {}}
                                getMessage={() => {}}
                                getUpdatedMetricData={getUpdatedMetricData}
                                countries={countries}
                                strategies={strategy}
                                strategy={metridata.strategy}
                                strategyName={metridata.strategy_name}
                            />
                            <DeleteMetric
                                id={metridata.id}
                                name={metridata.metric_name}
                                metricId={metricId}
                                getUpdatedMetricData={getUpdatedMetricData}
                                deleteUpdatedMessage={handleDeleteMessage}
                            />
                        </Box>
                    </Td>
                )}
            </Tr>
        ));
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {deleteMessage && <ErrorWidget message="Deleted Metric-Data Successfully" />}
            <Box>
                <DataFilter
                    fiscalYearDetail={fiscalYearDetail}
                    fiscalYearQtrDetail={fiscalYearQtrDetail}
                    filteredMetricData={filteredMetricData}
                />
            </Box>
            <TableContainer>
                {editable && <CreateMetricData getMetricData={getUpdatedMetricData} title={title} strategies={strategy} />}
                <Table size="sm">
                    <Thead>
                        <Tr>
                            <Th>Status</Th>
                            <Th>Strategy</Th>
                            <Th>Metric</Th>
                            <Th>Value</Th>
                            <Th>Fiscal Year</Th>
                            <Th>Fiscal QTR</Th>
                            <Th>Geography</Th>
                            <Th>Approve</Th>
                            {permissionType !== 'viewer' && <Th>Actions</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>{renderTableRows()}</Tbody>
                </Table>
            </TableContainer>
        </>
    );
};

export default MetricData;
