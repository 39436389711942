import React, { useContext, useState } from "react";
import {
  Box,
  Text,
  Select,
  Input,
  Button,
  VStack,
  HStack,
  Stack,
  FormControl,
  FormLabel,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";
import { DownloadIcon } from "@chakra-ui/icons";
import { EditContext } from "../../../../Context/BreadcrumbsContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../../../Services/API";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";

const RadioTabtwo = () => {
  const token = window.localStorage.getItem("accessToken");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [role, setRole] = useState("");
  const [pillar, setPillar] = useState("");
  const [team, setTeam] = useState("");
  const [loading, setLoading] = useState(false); // Loader state
  const [ulrData, setUlrData] = useState(null);
  const toast = useToast(); // Toast for user feedback
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal controls
  
  const { hasAllPermission } = useContext(EditContext);
console.log("hasAllPermission1",hasAllPermission)



const getFormattedDate = (currentDate) => {
  return (
    currentDate.getFullYear() +
    "-" +
    String(currentDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(currentDate.getDate()).padStart(2, "0")
  );
};
  // Function to fetch report data
  const getULRREport = async () => {
    try {
      setLoading(true); // Set loading to true when API call starts
      const start_Date = getFormattedDate(startDate && startDate);
      const end_Date = getFormattedDate(endDate && endDate);

      const response = await API.get(
        `/ulr?start_date=${start_Date}&end_date=${end_Date}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      console.log("Response", response);

      const data = response.data;
      console.log("DAU", response.data);
      if (response?.data?.length === 0) {
        toast({
          title: "Oops! No data available for the selected date range. Please change the selection.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
        return;
      }
      setUlrData(response.data); // Assuming the API response has data
      toast({
        title: "Report fetched successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Remove underscores from header names and adjust styles
      const processHeaders = (data) => {
        const headers = Object.keys(data[0]);
        const formattedHeaders = headers.map(
          (header) => header.replace(/_/g, " ") // Replace underscores with spaces
        );

        // Create a new array with formatted headers
        const newData = data.map((row) =>
          Object.fromEntries(
            headers.map((header, index) => [
              formattedHeaders[index],
              row[header],
            ])
          )
        );

        return { formattedHeaders, newData };
      };

      // Format headers and data
      const { formattedHeaders, newData } = processHeaders(data);

      // Create a worksheet from the data
      const ws = XLSX.utils.json_to_sheet(newData);

      // Dynamically calculate column widths
      const colWidths = formattedHeaders.map((col) => {
        const maxLength = Math.max(
          ...newData.map((row) => (row[col] ? row[col].toString().length : 0)),
          col.length // Include column header length
        );
        return { wch: maxLength + 2 }; // Add padding to width
      });
      ws["!cols"] = colWidths; // Apply column widths to worksheet

      // Apply header styling
      formattedHeaders.forEach((header, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex }); // Header cell address
        ws[cellAddress].s = {
          fill: { fgColor: { rgb: "000000" } }, // Black background
          font: {
            color: { rgb: "FFFFFF" },
            bold: true,
            sz: 12,
            name: "Arial",
            family: 2,
          }, // White, bold text with font size
          alignment: { horizontal: "center", vertical: "center" }, // Center alignment
        };

        // Ensure text is in uppercase
        if (ws[cellAddress].v) {
          ws[cellAddress].v = ws[cellAddress].v.toUpperCase();
        }
      });

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "User Login Report");
      XLSX.writeFile(wb, "User_Login_report.xlsx");
      setStartDate(null)
      setEndDate(null)
      setLoading(false);
      onClose(); // Close the modal after the report is fetched
    } catch (err) {
      console.error("Error fetching data", err);
      toast({
        title: "Error fetching report.",
        description: err.response?.data?.message || "Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      onClose(); // Close the modal on error
    } finally {
      onClose()
      setLoading(false); // Reset loading state after the API call finishes
    }
  };

  const handleDownloadReport = () => {
    // Validate all fields are filled
    if ( !startDate) {
    // if (!role || !pillar || !team || !startDate || !endDate) {
      toast({
        title: "Missing required fields.",
        description: "Please fill all fields before submitting.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    onOpen(); // Open the confirmation modal
  };

  return (
    <Box mt="0" paddingInline="18">
       {loading && <LoaderSpinner />}
      {/* Instruction Text */}
      <Box mb="4" p="4" borderWidth="1px" borderRadius="md">
        <Text fontSize="md" mb={0}>
        This report provides an overview of distinct users, including the number of login instances, most recent and earliest login timestamps, the associated pillar, pillar leader, and the region.
        </Text>
      </Box>

      <Box display="flex" justifyContent={"space-between"}>
        <Box>
          <Stack direction="column" pt={0}>
            {/* Roles or CEC */}
            {/* <FormControl isRequired>
              <FormLabel>Roles or CEC</FormLabel>
              <Select
                placeholder="Select a role or CEC"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="role1">Role 1</option>
                <option value="role2">Role 2</option>
              </Select>
            </FormControl> */}

            {/* Pillar */}
            {/* <FormControl isRequired>
              <FormLabel>Pillar</FormLabel>
              <Select
                placeholder="Select a pillar"
                value={pillar}
                onChange={(e) => setPillar(e.target.value)}
              >
                <option value="pillar1">Pillar 1</option>
                <option value="pillar2">Pillar 2</option>
              </Select>
            </FormControl> */}

            {/* Action Team */}
            {/* <FormControl isRequired>
              <FormLabel>Action Team</FormLabel>
              <Select
                placeholder="Select an action team"
                value={team}
                onChange={(e) => setTeam(e.target.value)}
              >
                <option value="team1">Team 1</option>
                <option value="team2">Team 2</option>
              </Select>
            </FormControl> */}

            {/* Date Inputs */}
            <HStack spacing="4">
              <FormControl isRequired>
                <FormLabel>Start Date</FormLabel>
                <DatePicker
                  placeholderText={"yyyy/mm/dd"}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy/MM/dd"
                  customInput={<Input placeholder="yyyy/mm/dd" w="130px" />}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>End Date</FormLabel>
                <DatePicker
                  placeholderText={"yyyy/mm/dd"}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="yyyy/MM/dd"
                  customInput={<Input placeholder="yyyy/mm/dd" w="130px" />}
                />
              </FormControl>
            </HStack>
          </Stack>
        </Box>

        <Box>
          <Button
            leftIcon={<DownloadIcon />}
            sx={{
              backgroundColor: "#00a0da",
              _hover: {
                backgroundColor: "blue.100", // Change to your desired hover color
              },
            }}
            colorScheme="blackAlpha"
            size="md"
            mt={7}
            onClick={handleDownloadReport}
          >
            Download XLS Report
          </Button>
        </Box>
      </Box>


  <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <VStack>
                <Text mt={4}>Fetching data... Please wait.</Text>
              </VStack>
            ) : (
              <Text>
              The report has been generated successfully. Please click “Download” to proceed.

              </Text>
            )}
          </ModalBody>
          <ModalFooter
            sx={{ background: "transparent", justifyContent: "space-around" }}
          >
            {!loading && (
              <>
              <Button
                sx={{ background: "#000", color: "#fff" }}
                onClick={onClose}
                isLoading={loading}
                >
                Cancel
              </Button>
              <Button
                sx={{ background: "#000", color: "#fff" }}
                onClick={getULRREport}
                isLoading={loading}
                >
                Download
              </Button>
                </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Confirmation and Loader */}
      {/* <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to download the report?</Text>
            {loading && (
              <Box display="flex" justifyContent="center" mt={4}>
                <Spinner size="xl" />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={getULRREport}
              isLoading={loading} // Show spinner on button while loading
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Box>
  );
};

export default RadioTabtwo;
