import React, { useState } from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import API from '../../../../Services/API';
import Styles from './ViewNotification.module.css';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const ViewNotification = ({ id, subject, message, notificaionReaded, getNotification }) => {
  const token = window.localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleOpen = () => {
    if (!notificaionReaded) {
      setIsLoading(true);
      const payload = {
        id: id,
        notificaion_readed: true,
      };

      API.put(`/custom-notification`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(() => {
          getNotification();
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
    onOpen();
  };

  const markAsUnread = () => {
    setSuccessMessage(null);
    setIsLoading(true);
    const payload = {
      id: id,
      notificaion_readed: false,
    };

    API.put(`/custom-notification`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        getNotification();
        setIsLoading(false);
        onClose();
        setSuccessMessage('Selected notification has been marked as unread.');
      })
      .catch(() => {
        setSuccessMessage('Failed to mark the notification as unread.');
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {successMessage && <ErrorWidget message={successMessage} />}
      <Text
        as="a"
        className={`${Styles.subject} ${!notificaionReaded ? Styles.unread : 'read'}`}
        onClick={handleOpen}
        cursor="pointer"
        aria-label="View Notification"
      >
        {subject}
      </Text>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader id="notification-details-header">{subject}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{message ? parse(message) : 'No content available.'}</ModalBody>
          <ModalFooter backgroundColor={'transparent'}>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              className={Styles.confirmButton}
              id={id}
              onClick={markAsUnread}
              backgroundColor={'black'}
              color={'#ffffff'}
            >
              Mark as unread
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

ViewNotification.propTypes = {
  id: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  message: PropTypes.string,
  notificaionReaded: PropTypes.bool.isRequired,
  getNotification: PropTypes.func.isRequired,
};

ViewNotification.defaultProps = {
  message: '',
};

export default ViewNotification;
