// import React, { useState } from 'react';
// import API from '../../Services/API';
// import { 
//   Box, FormControl, FormLabel, Input, Button, Text, Image, Card, CardBody 
// } from '@chakra-ui/react';
// import Logo from './images/logo.png';
// import LoaderSpinner from '../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
// import Styles from './Login.module.css';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [passwordError, setPasswordError] = useState('');
//   const [isLoading, setIsLoading] = useState(false);

//   const loginHandler = async () => {
//     setIsLoading(true);
//     if (!email || !password) {
//       setPasswordError("Please provide email and password");
//       setIsLoading(false);
//       return;
//     }

//     const body = { email, password };

//     try {
//       const response = await API.post(`/user-login`, body);
//       const token = response.data.access;

//       const userResponse = await API.get(`/user-management`, {
//         headers: { 'Authorization': `Bearer ${token}` },
//       });

//       window.localStorage.setItem('user', JSON.stringify(userResponse.data));
//       window.localStorage.setItem('sessionId',(response.data.session_id));
//       window.localStorage.setItem("accessToken", response.data.access);
//       window.localStorage.setItem("refreshToken", response.data.refresh);
//       window.location.href = "/dashboard";
//     } catch (error) {
//       const errorMessage = error?.response?.data?.error || 'An error occurred';
//       if (errorMessage === 'User not found' || errorMessage === 'Incorrect password') {
//         setPasswordError("Invalid credentials. Please try again with the correct email and password.");
//       } else {
//         setPasswordError("An unexpected error occurred. Please try again later.");
//       }
//       setIsLoading(false);
//     }
//   };

//   return (
//     <Box className={Styles.limiter}>
//       <Box className={Styles.container_login100}>
//         <Box className={Styles.wrap_login100}>
//           {isLoading && <LoaderSpinner />}
//           <Box textAlign="center" w="100%">
//             <Image src={Logo} m="0 auto" alt="Logo" />
//           </Box>
//           <Card w="100%">
//             <CardBody>
//               <Text className={Styles.text_login}>
//                 Please login using your work email
//               </Text>
//               <Text className={Styles.error}>
//                 {passwordError}
//               </Text>
//               <FormControl>
//               <FormLabel htmlFor="email" className={Styles.text_login}>Username / Email</FormLabel>
//   <Input 
//     id="email"
//     type="email" 
//     mb="15px" 
//     value={email}
//     onChange={(e) => setEmail(e.target.value)} 
//     placeholder="user@company.com" 
//     aria-describedby="email-helper-text"
//   />
  
//   <FormLabel htmlFor="password" className={Styles.text_login}>Password</FormLabel>
//   <Input 
//     id="password"
//     type="password" 
//     mb="15px" 
//     value={password}
//     onChange={(e) => setPassword(e.target.value)} 
//     placeholder="password" 
//     aria-describedby="password-helper-text"
//   />
  
  
//                 <Button
//                     className={Styles.loginButton}
//                     colorScheme="blue" 
//                     color="#ffffff"  // Text color (white)
//                     bg="#0056b3"     // Darker blue background for higher contrast
//                     _hover={{ bg: "#004494" }} // Darker hover effect for accessibility
//                     onClick={loginHandler}
//                 >
//                     Login
//                 </Button>
//               </FormControl>
//             </CardBody>
//           </Card>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Login;

import React, { Fragment, useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  Box,
  Button,
  Card,
  HStack,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"; // Chakra UI components for styling
import { useNavigate } from "react-router-dom";
import API from "../../Services/API";
import Logo from "./images/logo.png";
import LoaderSpinner from "../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import Styles from "./Login.module.css";
import DuoLogo from "../../../assets/images/Cisco-Duo-Logo-2-color-RGB.svg";
import CiscoLogo from "../../../assets/images/cisco-ar21.svg";

const Login = () => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async () => {
    setLoading(true);
    setError(null);
    try {
      await oktaAuth.signInWithRedirect();
    } catch (err) {
      console.error("Error during login:", err);
      setError("Failed to log in. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleLogout = async () => {
    setLoading(true);
    setError(null);
    try { 
      await oktaAuth.signOut({postLogoutRedirectUri:process.env.REACT_APP_POST_LOGOUT_URI});
    } catch (err) {
      console.error("Error during logout:", err);
      setError("Failed to logout. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const loginHandler = async (ssoToken) => {
    setLoading(true);

    try {
      const userResponse = await API.post(`/duo_callback`, {
        token: ssoToken,
      });

      const userManagementResponse = await API.get(`/user-management`, {
        headers: { Authorization: `Bearer ${userResponse.data.access}` },
      });

      window.localStorage.setItem(
        "user",
        JSON.stringify(userManagementResponse.data)
      );
      
      window.localStorage.setItem('sessionId',(userResponse?.data?.session_id));
      window.localStorage.setItem("accessToken", userResponse.data.access);
      window.localStorage.setItem("refreshToken", userResponse.data.refresh);
      window.location.href = "/dashboard";
    } catch (error) {
      window.localStorage.removeItem("accessToken");
      window.localStorage.removeItem("refreshToken");
      
      
      await oktaAuth.tokenManager.clear();
      
      console.log("userResponse", error?.response?.data?.error);
      
      const errorMessage =
        error?.response?.data?.error ||
        "An error occurred" ||
        "Access Denied: Please contact your administrator to request access";

      if (
        errorMessage === "User not found" ||
        errorMessage === "Incorrect password"
      ) {
        setError("Invalid credentials. Please try again .");
      } else if (
        errorMessage ==
        "Access Denied: Please contact your administrator to request access."
      ) {
        setError(
          "It seems that your account is not yet registered on this platform. Kindly reach out to the administrator to get access."
        );
      } else {
        setError(
          "Access Denied: Please contact your administrator to request access."
        );
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      const accessToken = authState.accessToken?.accessToken;
      const idToken = authState.idToken?.idToken;
      loginHandler(accessToken);
    }
  }, [authState]);

  // const
  // if (!authState) {
  //    return <Spinner size="xl" />;
  // }

  return (
    // <Box className={Styles.limiter}>
    //   <Box className={Styles.container_login100}>
    //     <Box className={Styles.wrap_login100}>
    //       <Box textAlign="center" w="100%">
    //         <Image src={Logo} m="0 auto" alt="Logo" />
    //       </Box>
    //       {loading && <LoaderSpinner />}
    //       {error && (
    //         <>
    //           <Text
    //             sx={{ width: "100%", minWidth: "500px" }}
    //             style={{ color: "red" }}
    //           >
    //             {error}
    //           </Text>
    //         </>
    //       )}
    //       {authState && !authState.isAuthenticated && (
    //         <Button
    //           className={Styles.loginButton}
    //           colorScheme="blue"
    //           color="#ffffff"
    //           bg="#0056b3"
    //           _hover={{ bg: "#004494" }}
    //           onClick={handleLogin}
    //           isLoading={loading}
    //           size="lg"
    //         >
    //           Login with SSO
    //         </Button>
    //       )}
    //       {console.log("userResponse", authState)}
    //     </Box>
    //   </Box>
    // </Box>
    <Fragment>
      <Box
        sx={{
          background: "#f7f7f7",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        h={"10vh"}
      >
        <Box p={5} textAlign={"-webkit-right"}>
          <Image src={Logo} width="50%" alt="Cypress Logo" />
        </Box>
      </Box>
      <Box background={"#f7f7f7"} display="flex" h={"90vh"}>
        <Box
          w="50%"
          color="white"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          p={6}
        >
          <Image src={CiscoLogo} width="80%" alt="Cypress Logo" />
        </Box>

        <Box
          w="50%"
          bg="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={5}
          background={"#f7f7f7"}
        >
          <Box w="450px" bg="white" p={7} borderRadius="md" shadow="lg">
            <VStack spacing={2} textAlign="center">
              <Image src={DuoLogo} width="40%" alt="Cypress Logo" />
              <Text fontSize="2xl" color="#3182CE" fontWeight="bold">
                Sign in with Cisco Duo
              </Text>
              {loading && <LoaderSpinner />}
              <Text fontSize="sm" color="gray.600">
                Single sign-on allows you to log in to dashboard in one single
                click.
              </Text>

              {error && <Text color="red">{error}</Text>}
              {(error=== "" )? (
                <Button colorScheme="blue" size="lg" onClick={handleLogin}>
                  Single Sign-On
                </Button>
              ) : (
                <Button colorScheme="blue" size="lg" onClick={handleLogout}>
                  Back to login
                </Button>
              )}
            </VStack>
            <Text textAlign={"center"} mt={5} fontSize="sm" color="gray.500">
              Don't have a Cisco account?{" "}
            </Text>
            <Text textAlign={"center"} fontSize="sm" color="gray.500">
              Please contact your administrator for support.
            </Text>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Login;