import React from "react";

const DocumentUrlViewer = ({ fileUrl }) => {
  const getEmbedUrl = (url) => {
    if (url.endsWith(".pdf")) {
        return `https://docs.google.com/gview?url=${encodeURIComponent(
            url
          )}&embedded=true`;// PDF files can be embedded directly
    //   return url;
    } else if (url.endsWith(".doc") || url.endsWith(".docx")) {
      // Use Google Docs Viewer for Word documents
      return `https://docs.google.com/gview?url=${encodeURIComponent(
        url
      )}&embedded=true`;
    } else {
      return null;
    }
  };

  const embedUrl = getEmbedUrl(fileUrl);

  if (!embedUrl) {
    return <p>Unsupported file format. Please provide a valid PDF or DOC file.</p>;
  }

  return (
    <div style={{ width: "100%", height: "600px", border: "1px solid #ccc" }}>
      <iframe
        src={embedUrl}
        width="100%"
        height="100%"
        frameBorder="0"
        title="Document Viewer"
      ></iframe>
    </div>
  );
};

export default DocumentUrlViewer;
