import React, { useState, useEffect } from 'react';
import Styles from '../metricdata.module.css';
import API from '../../../../Services/API';
import Select from 'react-select';
import { 
    useDisclosure, 
    Button, 
    Tooltip, 
    IconButton, 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalFooter, 
    ModalBody, 
    ModalCloseButton, 
    FormControl, 
    FormLabel, 
    Input, 
    SimpleGrid 
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const EditMetricData = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);

    const [defaultValues, setDefaultValues] = useState({});
    const [selectedMetric, setSelectedMetric] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [selectedPeriodName, setSelectedPeriodName] = useState('');
    const [selectedFyq, setSelectedFyq] = useState('');
    const [selectedFyqName, setSelectedFyqName] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedApproval, setSelectedApproval] = useState('');
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCountryName, setSelectedCountryName] = useState('');
    const [qtrListUp, setQtrListUp] = useState([]);
    const [strategy, setStrategy] = useState('');
    const [strategyName, setStrategyName] = useState('');
    const [message, setMessage] = useState(null);

    const fiscalYearDetail = props.fiscalYearDetail?.map(item => ({
        value: item.id,
        label: item.name
    })).sort((a, b) => parseInt(b.label.replace('FY', '')) - parseInt(a.label.replace('FY', '')));

    const fiscalYearQtrDetail = props.fiscalYearQtrDetail?.map(item => ({
        value: item.id,
        label: item.name
    }));

    const countriesList = Array.isArray(props.countries)
    ? props.countries
          .map((region) => ({ value: region.id, label: region.name }))
          .sort((a, b) => a.label.localeCompare(b.label))
    : [];

    const strategiesList = props.strategies?.map(item => ({
        value: item.id,
        label: item.name
    }));

    const updateQuarterList = (periodName) => {
        const selectedOptionNew = `FY${periodName.slice(-2)}`;
        const relatedQuarters = fiscalYearQtrDetail?.filter(item => item.label.includes(selectedOptionNew))
            .sort((a, b) => {
                const quarterA = parseInt(a.label.split(' ')[1].replace('Q', ''));
                const quarterB = parseInt(b.label.split(' ')[1].replace('Q', ''));
                return quarterB - quarterA;
            });
        setQtrListUp(relatedQuarters || []);
    };

    useEffect(() => {
        setDefaultValues({
            periodName: props.periodName,
            metricName: props.metricName,
            period: props.period,
            fyq: props.fyq,
            status: props.status,
            approvalStatus: props.approvalStatus,
            geography: props.geography,
            geographyName: props.geographyName,
            strategy: props.strategy,
            strategyName: props.strategyName
        });

        setSelectedMetric(props.metricName);
        setSelectedPeriod(props.period);
        setSelectedPeriodName(props.periodName);
        setSelectedFyq(props.fyq);
        setSelectedFyqName(props.fyqName);
        setSelectedStatus(props.status);
        setSelectedApproval(props.approvalStatus);
        setSelectedCountry(props.geography);
        setSelectedCountryName(props.geographyName);
        setStrategy(props.strategy);
        setStrategyName(props.strategyName);
    }, [props]);

    const handleOpen = () => {
        setSelectedPeriodName(defaultValues.periodName);
        setSelectedFyqName(defaultValues.fyqName);
        setSelectedMetric(defaultValues.metricName);
        setSelectedPeriod(defaultValues.period);
        setSelectedFyq(defaultValues.fyq);
        setSelectedStatus(defaultValues.status);
        setSelectedApproval(defaultValues.approvalStatus);
        setSelectedCountry(defaultValues.geography);
        setSelectedCountryName(defaultValues.geographyName);
        setStrategy(defaultValues.strategy);
        setStrategyName(defaultValues.strategyName);

        updateQuarterList(defaultValues.periodName);
        onOpen();
    };

    const handleSave = async () => {
        setMessage(null);
        const updatedValue = document.getElementById("updateValue")?.value || props.value;

        const hasChanges = 
            parseInt(updatedValue) !== parseInt(props.value) ||
            selectedPeriod !== props.period ||
            selectedFyq !== props.fyq ||
            selectedStatus !== props.status ||
            selectedCountry !== props.geography ||
            strategy !== props.strategy;

        if (!hasChanges) {
            setMessage("No changes are made");
            return;
        }

        try {
            setIsLoading(true);

            const body = {
                id: parseInt(props.id),
                name: selectedMetric,
                value: parseInt(updatedValue),
                ...(selectedPeriod && { fiscal_year: parseInt(selectedPeriod), fiscal_year_name: selectedPeriodName }),
                ...(selectedFyq && { fiscal_quarter: parseInt(selectedFyq), fiscal_quarter_name: selectedFyqName }),
                ...(selectedStatus && { metric_status: selectedStatus }),
                ...(strategy && { strategy }),
                ...(strategyName && { strategy_name: strategyName }),
                ...(selectedCountry && { geography: selectedCountry }),
                ...(selectedCountryName && { geography_name: selectedCountryName })
            };

            await API.put(`/metric-data`, body, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage("Metric updated successfully");
            const response = await API.get(`/my-task`, { headers: { Authorization: `Bearer ${token}` } });
            props.getUpdatedPending(response.data);
            props.getMessage(true);
            onClose();
        } catch (error) {
            console.error("Error updating metric:", error);
            setMessage(error.response?.data?.message || "An error occurred during the update");
        } finally {
            setIsLoading(false);
        }
    };

    const changePeriod = (event) => {
        setSelectedPeriod(event.value);
        setSelectedPeriodName(event.label);
        setSelectedFyq(null);
        updateQuarterList(event.label);
    };

    const changeFyq = (event) => {
        setSelectedFyq(event.value);
        setSelectedFyqName(event.label);
    };

    const changeStatus = (event) => {
        setSelectedStatus(event.value);
    };

    const changeCountry = (event) => {
        setSelectedCountry(event?.value);
        setSelectedCountryName(event?.label);
    };

    const changeStrategy = (event) => {
        setStrategy(event?.value);
        setStrategyName(event?.label);
    };

    const statusOptions = [
        { value: 'on-track', label: 'On Track' },
        { value: 'delay', label: 'Delayed' },
        { value: 'acceleration', label: 'Acceleration' }
    ];

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Tooltip id={props.id} label="Edit metric data" aria-label="Edit metric data">
                <IconButton
                    variant="solid"
                    aria-label="Edit"
                    fontSize="15px"
                    icon={<EditIcon />}
                    className={Styles.edit}
                    id={props.id}
                    onClick={handleOpen}
                />
            </Tooltip>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="800px">
                    <ModalHeader>Edit Metric Data</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleGrid columns={[1, 2]} spacing={4}>
                            <FormControl id="metric-name">
                                <FormLabel fontWeight="bold">Metric Name</FormLabel>
                                <Input value={selectedMetric || ''} isDisabled aria-label="Metric Name" />
                            </FormControl>
                            <FormControl mb="15px">
                                <FormLabel fontWeight="bold">Value</FormLabel>
                                <Input id="updateValue" defaultValue={props.value} />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bold">Period (FY)</FormLabel>
                                <Select
                                    options={fiscalYearDetail}
                                    value={fiscalYearDetail?.find(option => option.label === selectedPeriodName) || null}
                                    onChange={changePeriod}
                                    aria-label="Period Fiscal Year"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bold">Period (FQ)</FormLabel>
                                <Select
                                    options={qtrListUp}
                                    value={qtrListUp?.find(option => option.value === selectedFyq) || null}
                                    onChange={changeFyq}
                                    aria-label="Period Fiscal Quarter"
                                />
                            </FormControl>
                            <FormControl mb="15px">
                                <FormLabel fontWeight="bold">Status</FormLabel>
                                <Select
                                    options={statusOptions}
                                    onChange={changeStatus}
                                    value={statusOptions?.find(option => option.value === selectedStatus) || null}
                                />
                            </FormControl>
                            <FormControl mb="15px">
                                <FormLabel fontWeight="bold">Geography</FormLabel>
                                <Select
                                    options={countriesList}
                                    onChange={changeCountry}
                                    value={countriesList?.find(option => option.value === selectedCountry) || null}
                                />
                            </FormControl>
                            <FormControl mb="15px">
                                <FormLabel fontWeight="bold">Strategy</FormLabel>
                                <Select
                                    options={strategiesList}
                                    onChange={changeStrategy}
                                    value={strategiesList?.find(option => option.label === strategyName) || null}
                                    isDisabled
                                />
                            </FormControl>
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter backgroundColor="transparent">
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" mr={3} onClick={handleSave} isLoading={isLoading}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditMetricData;
