// import React, { useEffect, useState } from "react";
// import { Dropdown } from "react-bootstrap";
// import Face1 from "../../../../assets/images/userPlaceholder.svg";
// import { Redirect } from "react-router-dom";
// import API from "../../../Services/API";

// function Profile() {
//   const user = JSON.parse(window.localStorage.getItem("user"));
//   const sessionId = JSON.parse(window.localStorage.getItem("sessionId"));
//   const token = window.localStorage.getItem("accessToken")
//   const [isLoading, setIsLoading] = useState()
//   const [editable, setEditable] = useState();
//   const[profile, setProfile] = useState();

//   const logout = () => {
//      setIsLoading(true)
//      const formData ={id:sessionId&& sessionId}
//     API.post(`/user-logout`, {
//       ...formData,
//       headers: {
//         'Authorization': 'Bearer ' + token
//       }
//     }).then(response=>{
    
//       localStorage.removeItem("accessToken")
//       localStorage.removeItem("refreshToken")
//       localStorage.clear();
//       window.location.reload(false);
//       window.location.href = "/";
//       setIsLoading(false)
//     }).catch(error=>{
//       setIsLoading(false)
//     })

//   }

//   useEffect(() => {
//     setIsLoading(true)
//     API.get(`/user-management`, {
//       headers: {
//         'Authorization': 'Bearer ' + token
//       }
//     }).then(response=>{
//       setProfile(response.data)
//       setIsLoading(false)
//     }).catch(error=>{
//       setIsLoading(false)
//     })
//   }, []);

//   return (
//     <li className="nav-item nav-profile">
//       <Dropdown align={"right"}>
//         <Dropdown.Toggle className="nav-link" aria-expanded="false" aria-label="Open menu">
//           <div className="nav-profile-img">
//             <img
//               src={
//                 profile && profile.profile_picture !== null
//                   ?  profile && profile.profile_picture
//                   : Face1
//               }
//               alt={profile && profile.first_name+' '+profile && profile.last_name}
//             />
//           </div>
//           <div className="nav-profile-text">
//             <p className="mb-1 text-black">
//               <>
//                 {profile && profile.first_name} {profile && profile.last_name}
//               </>
//             </p>
//           </div>
//         </Dropdown.Toggle>
//         <Dropdown.Menu className="navbar-dropdown">
//           <Dropdown.Item href="/profile">
//             <i className="mdi mdi-cached mr-2 text-success"></i>
//             <>Profile</>
//           </Dropdown.Item>
//           {/* <Dropdown.Item href="/settings">
//             <i className="mdi mdi-settings mr-2 text-danger"></i>
//             <>Settings</>
//           </Dropdown.Item> */}
//           <Dropdown.Item onClick={logout}>
//             <i className="mdi mdi-logout mr-2 text-primary"></i>
//             <>Signout</>
//           </Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown>
//     </li>
//   );
// }

// export default Profile



import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Face1 from "../../../../assets/images/userPlaceholder.svg";
import { Redirect } from "react-router-dom";
import API from "../../../Services/API";
import { useOktaAuth } from "@okta/okta-react";

function Profile() {
  const { oktaAuth, authState } = useOktaAuth();
  const user = JSON.parse(window.localStorage.getItem("user"));
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading] = useState()
  const [editable, setEditable] = useState();
  const[profile, setProfile] = useState();

  // const logout = () => {
  //   localStorage.removeItem("accessToken")
  //   localStorage.removeItem("refreshToken")
  //   localStorage.clear();
  //   window.location.reload(false);
  //   window.location.href = "/";
  // }

  const logout = async () => {
    try {
      // Clear tokens and initiate logout process
      setIsLoading(true); // Show loading indicator
  
      // Perform Okta sign out and cleanup
      await oktaAuth.signOut({
        postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_URI,
      });
      await oktaAuth.tokenManager.clear();
      await oktaAuth.transactionManager.clear();
  
      // Safely retrieve sessionId from localStorage
      const sessionIdRaw = window.localStorage.getItem("sessionId");
      const sessionId = sessionIdRaw && sessionIdRaw !== "undefined" ? JSON.parse(sessionIdRaw) : null;
  
      // Prepare form data for the API call
      const formData = { id: sessionId };
  
      // Make API call to log out the user
      if (sessionId) {
        await API.post(`/user-logout`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
  
      // Clear localStorage and reload the page
      ["accessToken", "refreshToken", "sessionId"].forEach((key) =>
        localStorage.removeItem(key)
      );
      localStorage.clear(); // Ensure complete cleanup
  
      window.location.reload(false);
      window.location.href = "/";
    } catch (error) {
      console.error("Error during logout:", error);
  
      // Fallback redirect in case of an error
      window.location.href = "/";
    } finally {
      setIsLoading(false); // Stop loading indicator
    }
  };
  

  useEffect(() => {
    setIsLoading(true)
    API.get(`/user-management`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setProfile(response.data)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })
  }, []);

 

  return (
    <li className="nav-item nav-profile">
      <Dropdown align={"right"}>
        <Dropdown.Toggle className="nav-link">
          <div className="nav-profile-img">
            <img
              src={
                profile && profile.profile_picture !== null
                  ?  profile && profile.profile_picture
                  : Face1
              }
              alt={profile && profile.first_name+' '+profile && profile.last_name}
            />
          </div>
          <div className="nav-profile-text">
            <p className="mb-1 text-black">
              <>
                {profile && profile.first_name} {profile && profile.last_name}
              </>
            </p>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="navbar-dropdown">
          <Dropdown.Item href="/profile">
            <i className="mdi mdi-cached mr-2 text-success"></i>
            <>Profile</>
          </Dropdown.Item>
          {/* <Dropdown.Item href="/settings">
            <i className="mdi mdi-settings mr-2 text-danger"></i>
            <>Settings</>
          </Dropdown.Item> */}
          <Dropdown.Item onClick={logout}>
            <i className="mdi mdi-logout mr-2 text-primary"></i>
            <>Signout</>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );
}

export default Profile