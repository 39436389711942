import React, { useEffect, useState } from 'react';
import Styles from './Aspirations.module.css'
import { Box, Text, Spinner, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import API from '../../../Services/API';
import { TabContainer } from 'react-bootstrap';
import EditAspiration from './EditAspiration/EditAspiration';
import DeleteComponent from './DeleteComponent/DeleteComponent'
import ApproveComponent from './ApproveComponent/ApproveComponent';
import RejectComponent from './RejectComponent/RejectComponent';

const Aspirations = () => {
    const token = window.localStorage.getItem("accessToken")
    const [aspirations, setAspirations] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        API.get(`/my-task-aspiration`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const sortedMetrics = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            setAspirations(sortedMetrics)
        }).catch(error=>{
        })
    }, []);

    const getAspiration = () =>{
        API.get(`/my-task-aspiration`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const sortedMetrics = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            setAspirations(sortedMetrics)
        }).catch(error=>{
        })
    }

    return (
        <>
            <TabContainer>
                <Table>
                    <Thead>
                        <Tr bg="gray.100">
                            <Th className={Styles.th} color="blue.600">Name</Th>
                            <Th className={Styles.th} color="blue.600">Value</Th>
                            <Th className={Styles.th} color="blue.600">Period - Year</Th>
                            <Th className={Styles.th} color="blue.600">Period - Qtr</Th>
                            <Th className={Styles.th} color="blue.600">Approval</Th>
                            <Th className={Styles.th} color="blue.600">Approve</Th>
                            <Th className={Styles.th} color="blue.600">Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            aspirations && aspirations.map((aspiration, index) =>
                                <Tr className={Styles.tr}>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box>
                                            {aspiration.name}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {aspiration.value} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {aspiration.target_fiscal_year_name} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {aspiration.target_fiscal_quarter_name} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {aspiration.approval_status} </Td>
                                    <Td className={Styles.td+' actionButton'} textAlign={'center'}> 
                                        <ApproveComponent id={aspiration.id} getAspiration={getAspiration} />
                                        <RejectComponent id={aspiration.id} getAspiration={getAspiration} />
                                    </Td>
                                    <Td className={Styles.td+' actionButton'} textAlign={'center'}>
                                        <EditAspiration aspiration={aspiration} getAspiration={getAspiration} />
                                        <DeleteComponent id={aspiration.id} getAspiration={getAspiration} />
                                    </Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TabContainer>
        </>
    )
}

export default Aspirations