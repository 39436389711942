import React, { useState, useEffect } from 'react';
import { Box, SimpleGrid, Grid, GridItem, Button, Text } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import API from '../../../../../../../Services/API';
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../Widgets/ErrorWidget/ErrorWidget';
import AddSlideTwo from '../AddSlideTwo/AddSlideTwo';
import SectionOne from './SectionOne/SectionOne';
import SectionTwo from './SectionTwo/SectionTwo';
import SectionThree from './SectionThree/SectionThree';
import FiscalYear from '../SlideOne/FiscalYear/FiscalYear';
import DeleteAction from './DeleteAction/DeleteAction';
import Styles from '../executiveleadershipreviews.module.css';

const SlideTwo = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(true); // Loading initialized as true
    const [impactStories, setImpactStories] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    // Fetch Impact Stories on component mount or when pillarId changes
    useEffect(() => {
        fetchImpactStories(props.pillarId);
    }, [props.pillarId]);

    // Function to fetch impact stories
    const fetchImpactStories = (pillarId) => {
        setIsLoading(true);
        API.get(`/impact-story?action=${pillarId}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
        .then((response) => {
            setImpactStories(response.data);
            setIsLoading(false);
        })
        .catch((error) => {
            setErrorMessage(error.response?.data?.msg || 'Error fetching data');
            setIsLoading(false);
        });
    };

    // Function to update impact stories
    const getSecondSlideUpdate = (slideTwo) => {
        setImpactStories(slideTwo);
    };

    const getUpdatedImpactStory = () => {
        fetchImpactStories(props.pillarId);
    };

    // Submit for approval
    const submitForApproval = (event) => {
        setIsLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        const formData = new FormData();
        formData.append('id', parseInt(event.target.id));
        formData.append('approval_status', 'Submitted');

        API.put(`/impact-story`, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(() => {
                getUpdatedImpactStory();
                setIsLoading(false);
                setSuccessMessage('Impact story successfully submitted for approval');
            })
            .catch((error) => {
                setErrorMessage(error.response?.data?.msg || 'Error submitting for approval');
                setIsLoading(false);
            });
    };

    const getSecondSlideStatus = () =>{
        fetchImpactStories(props.pillarId);
    }

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {errorMessage && <ErrorWidget message={errorMessage} />}
            {successMessage && <ErrorWidget message={successMessage} />}
            
            <Box className={Styles.container}>
                {props.editable && (
                    <Box m={'10px'}>
                        <AddSlideTwo pillarId={props.pillarId} getSecondSlideUpdate={getSecondSlideUpdate} />
                        <Box className='clearfix'></Box>
                    </Box>
                )}

                {impactStories && impactStories.length > 0 ? (
                    impactStories.map((story, i) => (
                        <React.Fragment key={story.id}>
                            {props.editable && (
                                <Box>
                                    <DeleteAction
                                        id={story.id}
                                        label={story.label}
                                        getUpdatedImpactStory={getUpdatedImpactStory}
                                        getslide2Update={props.getslide2Update}
                                    />
                                </Box>
                            )}
                            <Box className={Styles.content}>
                                <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(3, 1fr)' gap={0}>
                                    <GridItem rowSpan={2} colSpan={1}>
                                        <Box className={Styles.leftGrid}>
                                            <SectionOne
                                                id={story.id}
                                                label={story.label}
                                                banner={story.banner}
                                                sublabel={story.sublabel}
                                                editable={props.editable}
                                                getSecondSlideUpdate={getSecondSlideUpdate}
                                                pillarId={props.pillarId}
                                                getslide2Update={props.getslide2Update}
                                            />
                                        </Box>
                                        <Box>
                                            <FiscalYear
                                                editable={props.editable}
                                                heading={props.slideData && props.slideData.heading1}
                                                description={props.slideData && props.slideData.description1}
                                                pillarId={props.pillarId}
                                                id={story.id}
                                                slideOneUpdate={props.slideOneUpdate}
                                                slideType={'slide2'}
                                                fiscalYear={story.fiscal_year}
                                                fiscalQtr={story.fiscal_quarter}
                                                getslide2Update={props.getslide2Update}
                                                getSecondSlideStatus={getSecondSlideStatus}

                                            />
                                        </Box>
                                    </GridItem>
                                    <GridItem rowSpan={2} colSpan={2}>
                                        {[story.heading1, story.heading2, story.heading3].map((heading, idx) => (
                                            <SectionTwo
                                                key={idx}
                                                id={story.id}
                                                editable={props.editable}
                                                getSecondSlideUpdate={getSecondSlideUpdate}
                                                pillarId={props.pillarId}
                                                heading={heading}
                                                banner={story[`banner${idx + 1}`]}
                                                description={story[`description${idx + 1}`]}
                                                getslide2Update={props.getslide2Update}
                                                index={idx + 1}
                                            />
                                        ))}
                                    </GridItem>
                                </Grid>
                            </Box>
                            <Box className={`${Styles.footer} footernew`} backgroundColor={'#00a0da'} textAlign={'left'} color={'#ffffff'}>
                                <SimpleGrid columns={[1, 2, 3]} spacing='20px'>
                                    {[story.footer1, story.footer2, story.footer3].map((footer, idx) => (
                                        <SectionThree
                                            key={idx}
                                            id={story.id}
                                            editable={props.editable}
                                            pillarId={props.pillarId}
                                            getSecondSlideUpdate={getSecondSlideUpdate}
                                            banner={story[`footerbanner${idx + 1}`]}
                                            description={footer}
                                            getslide2Update={props.getslide2Update}
                                            index={idx + 1}
                                        />
                                    ))}
                                </SimpleGrid>
                            </Box>
                            {story.approval_status === 'Ready to Submit' && (
                                <Box float={'right'} m={'5px'} textAlign={'right'}>
                                    <Button className='buttonPrimary' onClick={submitForApproval} id={story.id} index={i}>
                                        Submit for approval
                                    </Button>
                                </Box>
                            )}
                        </React.Fragment>
                    ))
                ) : (
                    <Text p={'20px'}>There is no data added yet</Text>
                )}
            </Box>
        </>
    );
};

export default SlideTwo;
