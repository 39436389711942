import React, { useState, useRef, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom'
import $ from 'jquery'
import Styles from './form.module.css'
import API from '../../../../Services/API'
import {Input, Stack, Button, Spinner, Image, Text, } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const Form = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(false)
    const [file, setFile] = useState(null);
    const editor = useRef(null);
    const [message, setMessage] = useState(false)   
    const {id} = useParams();
    const [error,setError] = useState(null)
    const { projectModules } = useContext(EditContext);
    console.log("projectModules",projectModules)
    function bannerUpload(e) {
        setError(null)
        const file = e?.target?.files[0];
        if (!file) {
            console.log("No file selected");
            return;
        }
        const fileType = file?.type;
        console.log("projectModules", fileType);
      if (fileType === "image/png" || fileType === "image/jpeg") {
        setFile(e.target.files[0]);
      } else {
        setError("only png / jpg is allowed.")
        e.target.value = null;
      }
    }

    const updateAboutSIoInfo = (event) =>{
        setError(null)
        setIsLoading(true)
        setMessage(false)
        if(props.count && props.count > 0){
            const formData = new FormData()
            if(file != null){
                formData.append('banner', file)
            }
            // formData.append('prop_label', $("#pagetitle").val())
            formData.append('description', $("#description").val())
            formData.append('id', props.info && props.info.id)
            API.put(`/about-info/`, formData, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                API.get(`/about-info/`, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                })
                .then((response) => {
                    setMessage(true)
                    props.getUpdateAbout(response)
                }).catch(error=>{
                    setIsLoading(false)
                })
                const aboutSiioId = projectModules.find(
                    (a) => a?.unique_name === "asio"
                  );
                const formData1 =  new FormData()
                formData1.append('id', aboutSiioId && aboutSiioId?.id)
                // formData1.append('name', $("#pagetitle").val())
                API.put(`/project-modules`, formData1, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    setIsLoading(false)
                    // window.location.reload()
                }).catch(error=>{
                    setIsLoading(false)
                })
            }).catch(error=>{
                setIsLoading(false)
            })
        }else{
            const formData = new FormData()
            if(file != null){
                formData.append('banner', file)
            }
            // formData.append('prop_label', $("#pagetitle").val())
            formData.append('description', $("#description").val())
            API.post(`/about-info/`, formData, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                API.get(`/about-info/`, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                })
                .then((response) => {
                    props.getUpdateAbout(response)
                }).catch(error=>{
                    setIsLoading(false)
                })
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }
    }
    
    return (
        <>
            { 
                isLoading ? <LoaderSpinner />: null
            }
              { message ? <ErrorWidget message={"About SIIO updated successfully "}/> : null}
        
            <Stack spacing={3} mb={'30px'}>
                {/* <Input
                    id={"pagetitle"} 
                    placeholder='Please Enter Title' 
                    size='md' 
                    backgroundColor={"#ffffff"} 
                    defaultValue={props.info && props.info.prop_label}
                /> */}
                <Input type="file" onChange={bannerUpload} padding={"4px"}/>
                {error && <Text color={"red"} >{error}</Text>}
               {props.info && props.info.banner && <Image src={props.info && props.info.banner} alt={props.info && props.info.prop_label}/>
               } <JoditEditor
                    id={"description"}
                    ref={editor}
                    value={props.info && props.info.description}
                    tabIndex={1} // tabIndex of textarea
                    onChange={newContent => {}}
                />
                <Button className='primaryButtonNew' w={100}  onClick={updateAboutSIoInfo}>
                    Save
                </Button>
                {/* isDisabled={!!error} */}
            </Stack>
        </>
    )
}

export default Form

