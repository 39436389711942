import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './executiveleadershipreviews.module.css'
import API from '../../../../../../Services/API'
import {Heading, Select, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import DeleteElr from './DeleteElr/DeleteElr'
import AddSlideOne from './AddSlideOne/AddSlideOne';
import SlideZero from './SlideZero/SlideZero';
import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo'
import SlideThree from './SlideThree/SlideThree';
import FiscalYear from './SlideOne/FiscalYear/FiscalYear';
import GeneratePdf from './GeneratePdf/GeneratePdf';
import ViewPdf from './ViewPdf/ViewPdf';
import ErrorWidget from '../../../../../Widgets/ErrorWidget/ErrorWidget'


const ExecutiveLeadershipReviews = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState(false)
    const [elrSectionOne, setElrSectionOne] = useState()
    const [elrMetric, setElrMetric] = useState()
    const [preview, setPreview] = useState(null);
    const [viewOneId, setViewOneId] = useState(null);
    const [impactStories, setImpactStories] = useState(null);
    const [elr, setElr] = useState(null);
    const [noData, setNoData] = useState(0);
    const editor = useRef(null);
    const [status, setStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [elrFile, setElrFile] = useState(null);
    const [submitMessage, setSubmitMessage] = useState(null);
    
    const [firstFiscalYear, setFirstFiscalYear] = useState(null)
    const [firstFiscalQtr, setFirstFiscalQtr] = useState(null)
    
    const [elrStatus, setElrStatus] = useState(null)
    const [impactStoryStatus, setImpactStoryStatus] = useState(null)
    const [elrReviewStatus, setElrReviewStatus] = useState(null)
    const [elrBanner, setElrBanner] = useState(null);
    const printSectionRef = useRef(null);
    const [getElrDeleteSuccess, setGetElrDeleteSuccess] = useState(null);
    const [errorWidget, setErrorWidget] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [dataLength, setDataLength] = useState(0);

    const [elreportingDownloadStatus, setElreportingDownloadStatus] = useState(null);
    const [impactStoryDownloadStatus, setImpactStoryDownloadStatus] = useState(null);
    const [elreviewDownloadStatus, setElreviewDownloadStatus] = useState(null);

    useEffect(()=>{
        setIsLoading(true)
        API.get(`/elr-dashboard?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            console.log(response.data.length)
            setElrSectionOne(response.data)
            setPreview(response.data && response.data[0].banner)
            setViewOneId(response.data && response.data[0].id)
            setStatus(response.data && response.data[0].approval_status)
            setElrStatus(response.data && response.data[0].approval_status)
            setFirstFiscalYear(response.data && response.data[0].fiscal_year_name)
            setFirstFiscalQtr(response.data && response.data[0].fiscal_quarter_name)
            setDataLength(response.data && response.data.length)
            setIsLoading(false)
            setNoData(0)
            const data = response.data;
            const notReadyStatuses = [
                "Ready to Submit",
                "Submitted",
                "Approved",
                "Approved by Comms Team",
                "Rejected",
            ];
            
            if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                setElreportingDownloadStatus("notready");
            } else {
                setElreportingDownloadStatus("readyfordownload");
            }
        }).catch(error=>{
            setIsLoading(false)
            setNoData(1)
        })

        API.get(`/impact-story?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setImpactStories(response.data)
            setImpactStoryStatus(response.data && response.data[0].approval_status)

            const data = response.data;
            const notReadyStatuses = [
                "Ready to Submit",
                "Submitted",
                "Approved",
                "Approved by Comms Team",
                "Rejected",
            ];
            
            if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                setImpactStoryDownloadStatus("notready");
            } else {
                setImpactStoryDownloadStatus("readyfordownload");
            }



            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })

        API.get(`/executive-level-review?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const data = response.data;
            const notReadyStatuses = [
                "Ready to Submit",
                "Submitted",
                "Approved",
                "Approved by Comms Team",
                "Rejected",
            ];
            
            if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                setElreviewDownloadStatus("notready");
            } else {
                setElreviewDownloadStatus("readyfordownload");
            }

            // if (data.every((item) => item.approval_status === "Approved by Action Office")) {
            //     setElrReviewStatus("Approved by Action Office");
            // }else if (data.every((item) => item.approval_status === "Approved by Super Admin")) {
            //     setElrReviewStatus("Approved by Super Admin");
            // }else{
            //     setElrReviewStatus("Submitted");
            // }
            
            if (data.every((item) => item.approval_status === "Approved by Action Office")) {
                setElrReviewStatus("All Approved by Action Office");
            } else if (data.some((item) => item.approval_status === "Approved by Action Office")) {
                setElrReviewStatus("Approved by Action Office");
            } else if (data.some((item) => item.approval_status === "Approved by Super Admin")) {
                setElrReviewStatus("Approved by Super Admin");
            } else {
                setElrReviewStatus("Submitted");
            }
            

        //   const allApprovedBySuperAdmin = response.data.some(
        //     (item) => item.approval_status === "Approved by Super Admin"
        //   );

        //   const allApprovedByActionOffice = response.data.some(
        //     (item) => item.approval_status === "Approved by Action Office"
        //   );

        //   if(allApprovedByActionOffice) {
        //     setElrReviewStatus("Approved by Action Office");
        //   }else if (allApprovedBySuperAdmin) {
        //     setElrReviewStatus("Approved by Super Admin");
        //   }else {
        //     setElrReviewStatus("Submitted"); // Fallback case
        //   }

          
          setElr(response.data);
          setIsLoading(false);
        }).catch(error=>{
            setIsLoading(false)
        })
    },[token, props.pillarId])

    function elrBannerUpload(e) {
        const selectedFile = e.target.files[0];
        setElrBanner(selectedFile);
    }

    const addElr = (event) => {
        setIsLoading(true)
        const formData = new FormData()
        if(elrBanner != null){
            formData.append('banner', elrBanner)
        }
        formData.append('label', $("#elr_label").val())
        formData.append('description', $("#elrDescription").val())
        formData.append('action', parseInt(props.pillarId))
        formData.append('approval_status','Ready to Submit')
        
        API.post(`/executive-level-review`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/executive-level-review?action=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                const data = response.data;
                const notReadyStatuses = [
                    "Ready to Submit",
                    "Submitted",
                    "Approved",
                    "Approved by Comms Team",
                    "Rejected",
                ];
                
                if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                    setElreviewDownloadStatus("notready");
                } else {
                    setElreviewDownloadStatus("readyfordownload");
                }

                const allApprovedactionoffice = response.data.every(item => item.approval_status === ( "Approved by Action Office"));
                const allApprovedsuperadmin = response.data.every(item => item.approval_status === ("Approved by Super Admin"));

                if (allApprovedactionoffice) {
                    setElrReviewStatus("Approved by Action Office")
                } else if(allApprovedsuperadmin){
                    setElrReviewStatus("Approved by Super Admin")
                }else {
                    // setElrStatus("Submitted")
                }
                
                setElr(response.data)
                setIsLoading(false)
                onClose()
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)

        })
    }

    const getUpdateElr = (elrView) =>{
        API.get(`/executive-level-review?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const allApprovedactionoffice = response.data.every(item => item.approval_status === ( "Approved by Action Office"));
            const allApprovedsuperadmin = response.data.every(item => item.approval_status === ("Approved by Super Admin"));
        
            if (allApprovedactionoffice) {
                setElrReviewStatus("Approved by Action Office")
            } else if(allApprovedsuperadmin){
                setElrReviewStatus("Approved by Super Admin")
            }else {
                // setElrStatus("Submitted")
            }

            const data = response.data;
            const notReadyStatuses = [
                "Ready to Submit",
                "Submitted",
                "Approved",
                "Approved by Comms Team",
                "Rejected",
            ];
            
            if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                setElreviewDownloadStatus("notready");
            } else {
                setElreviewDownloadStatus("readyfordownload");
            }

            setElr(response.data)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    const getFirstSlideUpdate = (slideOne) =>{
        setElrSectionOne(slideOne)
        setPreview(slideOne && slideOne.banner)
        setViewOneId(slideOne && slideOne.id)
    }

    const slideOneUpdate = (slideOneUpdate) => {
        API.get(`/elr-dashboard?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            console.log(response.data.length)
            setElrSectionOne(response.data)
            const elrData = response.data;
            if (elrData && elrData.length > 0) {
                setElrSectionOne(elrData);
                setPreview(elrData[0].banner);
                setViewOneId(elrData[0].id);
                setStatus(elrData[0].approval_status);
                setElrStatus(elrData[0].approval_status);
                setFirstFiscalYear(elrData[0].fiscal_year_name);
                setFirstFiscalQtr(elrData[0].fiscal_quarter_name);
            }else{
                setFirstFiscalYear(null);
                setFirstFiscalQtr(null);
            }
            
            setIsLoading(false)
            setNoData(0)
            
            const allApprovedactionoffice = response.data.every(item => item.approval_status === ( "Approved by Action Office"));
            const allApprovedsuperadmin = response.data.every(item => item.approval_status === ("Approved by Super Admin"));
            // console.log("status",allApprovedactionoffice,allApprovedsuperadmin)
        
            if (allApprovedactionoffice) {
                setElrStatus("Approved by Action Office")
            } else if(allApprovedsuperadmin){
                setElrStatus("Approved by Super Admin")
            }else {
                setElrStatus("Submitted")
            }
            
            const data = response.data;
            const notReadyStatuses = [
                "Ready to Submit",
                "Submitted",
                "Approved",
                "Approved by Comms Team",
                "Rejected",
            ];
            
            if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                setElreportingDownloadStatus("notready");
            } else {
                setElreportingDownloadStatus("readyfordownload");
            }

        }).catch(error=>{
            setIsLoading(false)
            setNoData(1)
        })
    }

    

    const getNewUpdate = ()=>{
        API.get(`/executive-level-review?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            onClose()
            setElr(response.data)
            setIsLoading(false)
            const data = response.data;
            const notReadyStatuses = [
                "Ready to Submit",
                "Submitted",
                "Approved",
                "Approved by Comms Team",
                "Rejected",
            ];
            
            if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                setElreviewDownloadStatus("notready");
            } else {
                setElreviewDownloadStatus("readyfordownload");
            }
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    const getslide2Update = () =>{
        API.get(`/impact-story?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setImpactStories(response.data)
            const allApprovedactionoffice = response.data.every(item => item.approval_status === ( "Approved by Action Office"));
            const allApprovedsuperadmin = response.data.every(item => item.approval_status === ("Approved by Super Admin"));
            if (allApprovedactionoffice) {
                setImpactStoryStatus("Approved by Action Office")
            } else if(allApprovedsuperadmin){
                setImpactStoryStatus("Approved by Super Admin")
            }else {
                setImpactStoryStatus("Submitted")
            }

            const data = response.data;
            const notReadyStatuses = [
                "Ready to Submit",
                "Submitted",
                "Approved",
                "Approved by Comms Team",
                "Rejected",
            ];
            
            if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                setImpactStoryDownloadStatus("notready");
            } else {
                setImpactStoryDownloadStatus("readyfordownload");
            }

            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    const getslide3Update = () =>{
        API.get(`/executive-level-review?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const allApprovedactionoffice = response.data.every(item => item.approval_status === ( "Approved by Action Office"));
            const allApprovedsuperadmin = response.data.every(item => item.approval_status === ("Approved by Super Admin"));
            // console.log("status2",response?.data)
            if (allApprovedactionoffice) {
                setElrReviewStatus("Approved by Action Office")
            } else if(allApprovedsuperadmin){
                setElrReviewStatus("Approved by Super Admin")
            }else {
                setElrReviewStatus("Submitted")
            }
            setElr(response.data)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    }

    const getStatus = ()=>{
        API.get(`/elr-dashboard?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setElrSectionOne(response.data)
            setPreview(response.data && response.data[0].banner)
            setViewOneId(response.data && response.data[0].id)
            setStatus(response.data && response.data[0].approval_status)
            setElrStatus(response.data && response.data[0].approval_status)
            setFirstFiscalYear(response.data && response.data[0].fiscal_year)
            setFirstFiscalQtr(response.data && response.data[0].fiscal_quarter)
            setIsLoading(false)
            setNoData(0)
            const data = response.data;
            const notReadyStatuses = [
                "Ready to Submit",
                "Submitted",
                "Approved",
                "Approved by Comms Team",
                "Rejected",
            ];
            
            if (data.some((item) => notReadyStatuses.includes(item.approval_status))) {
                setElreportingDownloadStatus("notready");
            } else {
                setElreportingDownloadStatus("readyfordownload");
            }
        }).catch(error=>{
            setIsLoading(false)
            setNoData(1)
        })
    }

    const showWidget = () =>{
        setErrorWidget(true)
    }

    const submitForApproval = async (event) =>{
        setSuccessMessage(null)
        setIsLoading(true)
        const formData = new FormData();
        formData.append('id', event.target.id);
        formData.append('approval_status', 'Submitted');
        try {
            await API.put(`/executive-level-review`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            setIsLoading(false)
            setSuccessMessage('Submited ELR Successfully');
            getslide3Update();
        } catch (error) {
            setErrorMessage(error.response?.data?.msg || 'An error occurred');
            setIsLoading(false)
        }
    }

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null }
            {
                successMessage !== null ? <ErrorWidget message={successMessage} />  : null
            }
            {
                errorMessage !== null ? <ErrorWidget message={errorMessage} />  : null
            }

            {/* {
                errorMessage !== null ? <Box p={'0px 0 10px 10px'}> {errorMessage && errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Box> : null
            } */}
            
            {console.log("status1", elrStatus, impactStoryStatus, elrReviewStatus)}
            
            {/* {
               ( elrStatus && elrStatus === "Approved by Action Office" || elrStatus && elrStatus === "Approved by Super Admin") &&
                (impactStoryStatus && impactStoryStatus === "Approved by Action Office" || impactStoryStatus && impactStoryStatus === "Approved by Super Admin") &&
                (elrReviewStatus && elrReviewStatus === "Approved by Action Office"|| elrReviewStatus && elrReviewStatus === "Approved by Super Admin") && (
                    <ViewPdf
                        pillarId={props.pillarId}
                        title={props.title && props.title} 
                        firstFiscalYear={firstFiscalYear} 
                        firstFiscalQtr={firstFiscalQtr}
                    />
                )
            } */}
            {
                elreportingDownloadStatus === "readyfordownload" && 
                impactStoryDownloadStatus === "readyfordownload" && 
                elreviewDownloadStatus === "readyfordownload" ? (
                    <ViewPdf
                        pillarId={props.pillarId}
                        title={props.title} 
                        firstFiscalYear={firstFiscalYear} 
                        firstFiscalQtr={firstFiscalQtr}
                    />
                ) : null
            }

            {/* <ViewPdf
                pillarId={props.pillarId}
                title={props.title && props.title} 
                firstFiscalYear={firstFiscalYear} 
                firstFiscalQtr={firstFiscalQtr}
            /> */}

            {submitMessage !== null ? <Box> {submitMessage} </Box> : null}

            <SimpleGrid columns={[1]} spacing='10px'>
                <Heading className={Styles.slideHeading} visibility={'hidden'}> Executive Leader Reporting 
                    <GeneratePdf status={status} name={''} />
                </Heading>
                <Box id='pdf-content0'>
                    <SlideZero id='pdf-content0' 
                        title={props.title && props.title} 
                        firstFiscalYear={firstFiscalYear} 
                        firstFiscalQtr={firstFiscalQtr} 
                        pillarId={props.pillarId}
                    />
                </Box>

                <Box id='pdf-content1'>
                    <Heading className={Styles.slideHeading}> Executive Leader Reporting 
                        {
                           ( elrStatus && elrStatus === "Approved by Action Office" || elrStatus && elrStatus === "Approved by Super Admin") &&
                           (impactStoryStatus && impactStoryStatus === "Approved by Action Office" || impactStoryStatus && impactStoryStatus === "Approved by Super Admin") &&
                           (elrReviewStatus && elrReviewStatus === "Approved by Action Office"|| elrReviewStatus && elrReviewStatus === "Approved by Super Admin") &&  (
                                <GeneratePdf status={status} name={'pdf-content1'} />
                            )
                        }
                    </Heading>
                    {
                        props.editable && (elrSectionOne && elrSectionOne.length < 1) ? <AddSlideOne pillarId={props.pillarId} getFirstSlideUpdate={getFirstSlideUpdate} /> : null
                    }
                    {
                        elrSectionOne && elrSectionOne.length < 1 ? 
                        <Box border={'1px solid #e2e8f0'}>
                            <Text p={'20px'}> There is no data added yet </Text>
                        </Box> : null
                    }

                    {
                        elrSectionOne && elrSectionOne.map(slide1 =>
                            <>
                                <SlideOne 
                                    id={slide1.id}
                                    status={status} 
                                    editable={props.editable}
                                    preview={preview}
                                    slideData={slide1}
                                    pillarId={props.pillarId}
                                    heading1={slide1.heading1}
                                    description1={slide1.description1}
                                    heading2={slide1.heading2}
                                    description2={slide1.description2}
                                    heading3={slide1.heading3}
                                    description3={slide1.description3}
                                    heading4={slide1.heading4}
                                    description4={slide1.description4}
                                    elrMetric={elrMetric && elrMetric}
                                    firstFiscalYear = {slide1.fiscal_year_name}
                                    firstFiscalQtr={slide1.fiscal_quarter_name}
                                    fiscalYear={slide1.fiscal_year_name}
                                    fiscalQtr={slide1.fiscal_quarter_name}
                                    fiscalYearId={slide1.fiscal_year}
                                    fiscalQtrId={slide1.fiscal_quarter}
                                    actionStatus={slide1.action_status}
                                    getStatus={getStatus}
                                    itemStatus={slide1.approval_status}
                                    fiscalyearName = {slide1.fiscal_year_name}
                                    metricResult={slide1.get_elr_metric_data && slide1.get_elr_metric_data}
                                    slideOneUpdate={slideOneUpdate}
                                />
                            </>
                        )
                    }

                </Box>
                <Box id='pdf-content2' ref={printSectionRef}>
                    <Heading className={Styles.slideHeading} mt={'15px'}> Impact Stories
                        
                        {
                            ( elrStatus && elrStatus === "Approved by Action Office" || elrStatus && elrStatus === "Approved by Super Admin") &&
                            (impactStoryStatus && impactStoryStatus === "Approved by Action Office" || impactStoryStatus && impactStoryStatus === "Approved by Super Admin") &&
                            (elrReviewStatus && elrReviewStatus === "Approved by Action Office"|| elrReviewStatus && elrReviewStatus === "Approved by Super Admin") &&  (
                                <GeneratePdf status={status} name={'pdf-content2'} />
                            )
                        }
                    </Heading>
                    <SlideTwo
                        editable={props.editable}
                        pillarId={props.pillarId}
                        getslide2Update={getslide2Update}
                    />
                </Box>
                
                <Box>
                    <Heading className={Styles.slideHeading} mt={'15px'} > Executive Level Review 
                       
                        {
                             ( elrStatus && elrStatus === "Approved by Action Office" || elrStatus && elrStatus === "Approved by Super Admin") &&
                             (impactStoryStatus && impactStoryStatus === "Approved by Action Office" || impactStoryStatus && impactStoryStatus === "Approved by Super Admin") &&
                             (elrReviewStatus && elrReviewStatus === "Approved by Action Office"|| elrReviewStatus && elrReviewStatus === "Approved by Super Admin") &&  (
                                <GeneratePdf status={status} name={'pdf-content3'} />
                            )
                        }
                    </Heading>
                    {getElrDeleteSuccess && getElrDeleteSuccess !== null ? <Box color={'blue'}> {getElrDeleteSuccess && getElrDeleteSuccess} </Box> : null}
                    <Box border={'1px solid #e2e8f0'} id='pdf-content3' p={'0px 10px 10px 10px'}>
                    {props.editable ? 
                        <>
                            {
                                elr && elr.length >= 3 ? (
                                    <Box>
                                    <Button className={'defaultButton newElrButton'} onClick={showWidget}> Create ELR</Button>
                                    <Box className='clearfix'></Box>
                                    </Box>
                                ) : (
                                    <Box>
                                    <Button className={'defaultButton newElrButton'} onClick={onOpen}> Create ELR</Button>
                                    <Box className='clearfix'></Box>
                                    </Box>
                                )
                            }
                        </>
                    : null}
                    {
                        errorWidget && errorWidget ? <ErrorWidget message={'You can create up to three labels in ELR. If you’d like to add an additional one, please delete an existing ELR label.'} /> : null
                    }
                    
                    <Box className='clearfix'></Box>
                        {
                            elr && elr.length < 1 ? <Text p={'20px'}> There is no data added yet </Text> : null
                        }
                        
                        <SimpleGrid spacing={4} columns={[3]}>
                            {
                                elr && elr.map((elr, i)=>
                                    <Card>
                                        {
                                            props.editable ? <DeleteElr id={elr.id} getUpdateElr={getUpdateElr} pillarId={props.pillarId}/> : null
                                        }
                                        {
                                            props.editable ? 
                                            <>
                                                <CardHeader>
                                                    <FiscalYear
                                                        editable = {props.editable}
                                                        pillarId={props.pillarId}
                                                        id={elr.id}
                                                        slideType={'slide3'}
                                                        fiscalYear={elr.fiscal_year}
                                                        fiscalQtr={elr.fiscal_quarter}
                                                        getslide3Update={getslide3Update}
                                                    />
                                                </CardHeader>
                                                <SlideThree
                                                    id={elr.id}
                                                    label={elr.label}
                                                    pillarId={props.pillarId}
                                                    getUpdate={getNewUpdate}
                                                    description={elr.description}
                                                    getslide3Update={getslide3Update}
                                                />
                                            </> : 
                                            <>
                                                <CardHeader>
                                                    <Image src={elr.banner} width={'60px'} alt={elr.label} crossOrigin="anonymous" />
                                                    <Heading size='md' className={Styles.boxHeading}> {elr.label}</Heading>
                                                </CardHeader>
                                                <CardBody className='checkbody'>
                                                    {elr.description != null  ? parse(elr.description) : ''}
                                                </CardBody>
                                            </>
                                        }
                                        {
                                            elr.approval_status === 'Ready to Submit' ? 
                                                <>
                                                    <Box float={'right'} m={'5px'} textAlign={'right'} id={elr.id}> 
                                                        <Button className='buttonPrimary' onClick={submitForApproval} id={elr.id}> Submit for approval </Button>
                                                    </Box>
                                                </>
                                            : null
                                        }
                                    </Card> 
                                )
                            }
                        </SimpleGrid>
                    </Box>
                    
                    {/* {status !== 'Approve' ? <Button className='buttonPrimary' display={'block'} m={'20px auto'} onClick={submitElr}> Submit for approval </Button>:null} */}
                </Box>
            </SimpleGrid>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex} maxW={'580px'}>
                    <ModalHeader>Add new ELR</ModalHeader>
                    <ModalCloseButton />
                        <>
                            <ModalBody>
                                <FormControl>
                                    <FormLabel>Label</FormLabel>
                                    <Input type='text' id={'elr_label'} mb={'15px'} />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Icon</FormLabel>
                                    <Input type="file" onChange={elrBannerUpload} padding={"4px"}/>
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Description</FormLabel>
                                    <JoditEditor
                                        id={"elrDescription"}
                                        ref={editor}
                                        tabIndex={1} // tabIndex of textarea
                                        onChange={newContent => {}}
                                    />
                                </FormControl>
                            </ModalBody>        
                            <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                                <Button colorScheme='blue' mr={3} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button variant='ghost' onClick={addElr}>Add</Button>
                            </ModalFooter>
                        </>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ExecutiveLeadershipReviews