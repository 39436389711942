import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  VStack,
  useToast,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DownloadIcon } from "@chakra-ui/icons";
import API from "../../../../Services/API";
import * as XLSX from "xlsx";
import { EditContext } from "../../../../Context/BreadcrumbsContext";

function RadioTabone() {
  const token = window.localStorage.getItem("accessToken");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [uptimeMin, setUptimeMin] = useState(0);
  const [uptimeMax, setUptimeMax] = useState(100);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { hasAllPermission } = useContext(EditContext);
  
console.log("hasAllPermission6",hasAllPermission)

  const toast = useToast(); // Toast for user feedback

  const getFormattedDate = (currentDate) => {
    return (
      currentDate.getFullYear() +
      "-" +
      String(currentDate.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(currentDate.getDate()).padStart(2, "0")
    );
  };

  const getImpactStoriesReport = async () => {
    setIsLoading(true);
    try {
      const start_Date = getFormattedDate(startDate);
      const end_Date = getFormattedDate(endDate);
      const up_time_min = uptimeMin;
      const up_time_max = uptimeMax;

      const response = await API.get(
        // `/aws-uptime?end_date=${end_Date}&start_date=${start_Date}`,
        `/aws-uptime?end_date=${end_Date}&start_date=${start_Date}&min_uptime=${up_time_min}&max_uptime=${up_time_max}`,
           {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const data = response.data;
      console.log("DAU", response.data);
      if (response?.data?.length === 0) {
        toast({
          title: "Oops! No data available for the selected date range. Please change the selection.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
        setIsPopupOpen(false);
        return;
      }
      toast({
        title: "Report fetched successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Remove underscores from header names and adjust styles
      const processHeaders = (data) => {
        const headers = Object.keys(data[0]);
        const formattedHeaders = headers.map(
          (header) => header.replace(/_/g, " ") // Replace underscores with spaces
        );

        // Create a new array with formatted headers
        const newData = data.map((row) =>
          Object.fromEntries(
            headers.map((header, index) => [
              formattedHeaders[index],
              row[header],
            ])
          )
        );

        return { formattedHeaders, newData };
      };

      // Format headers and data
      const { formattedHeaders, newData } = processHeaders(data);

      // Create a worksheet from the data
      const ws = XLSX.utils.json_to_sheet(newData);

      // Dynamically calculate column widths
      const colWidths = formattedHeaders.map((col) => {
        const maxLength = Math.max(
          ...newData.map((row) => (row[col] ? row[col].toString().length : 0)),
          col.length // Include column header length
        );
        return { wch: maxLength + 2 }; // Add padding to width
      });
      ws["!cols"] = colWidths; // Apply column widths to worksheet

      // Apply header styling
      formattedHeaders.forEach((header, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex }); // Header cell address
        ws[cellAddress].s = {
          fill: { fgColor: { rgb: "000000" } }, // Black background
          font: {
            color: { rgb: "FFFFFF" },
            bold: true,
            sz: 12,
            name: "Arial",
            family: 2,
          }, // White, bold text with font size
          alignment: { horizontal: "center", vertical: "center" }, // Center alignment
        };

        // Ensure text is in uppercase
        if (ws[cellAddress].v) {
          ws[cellAddress].v = ws[cellAddress].v.toUpperCase();
        }
      });

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "AWS_availability_report");
      XLSX.writeFile(wb, "AWS_availability_report.xlsx");
      setStartDate(null)
      setEndDate(null)
      setIsLoading(false);
      setIsPopupOpen(false);
    } catch (err) {
      console.error("Error fetching data:", err.response.data.error);
      toast({
        title: err && err?.response && err?.response?.data && err?.response?.data?.error,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
      setIsPopupOpen(false);
    }
  };

  const handleDownloadReport = () => {
    if (!startDate || !endDate) {
    // if (!startDate || !endDate || !uptimeMin || !uptimeMax) {
      toast({
        title: "Missing required fields.",
        description: "Please fill all fields before submitting.",
        status: "warning",
        duration: 4000,
        isClosable: true,
      });
      return;
    }
    setErrorMessage("");
    setIsPopupOpen(true); // Show the popup
  };

  return (
    <Box mt="0" paddingInline="18">
      <Box p="4" borderWidth="1px" borderRadius="md" mb={4}>
        <Text fontSize="md" mb={0}>
        Monthly report on the availability of the digital solution.
        </Text>
      </Box>
      <Stack spacing={6} flex={"1"} >
        {/* Row 1: Uptime and Download Button */}
        <Box display="flex" alignItems={"flex-end"} justifyContent={"space-between"}>
          <Box>
            <Stack direction={["column", "row"]} spacing={4} align="flex-end">
              <FormControl>
                <FormLabel>Uptime Min (%)</FormLabel>
                <Input
                  type="number"
                  value={uptimeMin}
                  onChange={(e) => setUptimeMin(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Uptime Max (%)</FormLabel>
                <Input
                  type="number"
                  value={uptimeMax}
                  onChange={(e) => setUptimeMax(e.target.value)}
                />
              </FormControl>
            </Stack>

            {/* Row 2: Date Inputs */}
            <Stack
              direction={["column", "row"]}
              spacing={4}
              pt={5}
              align="center"
            >
              <FormControl isRequired>
                <FormLabel>Start Date</FormLabel>
                <DatePicker
                  placeholderText={"yyyy/mm/dd"}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="yyyy/MM/dd"
                  customInput={<Input placeholder="yyyy/mm/dd" />}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>End Date</FormLabel>
                <DatePicker
                  placeholderText={"yyyy/mm/dd"}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="yyyy/MM/dd"
                  customInput={<Input placeholder="yyyy/mm/dd" />}
                />
              </FormControl>
            </Stack>
          </Box>
          <Box>
            <FormControl alignItems={""}>
              <Button
                leftIcon={<DownloadIcon />}
                sx={{
                  backgroundColor: "#00a0da",
                  _hover: {
                    backgroundColor: "blue.100",
                  },
                }}
                colorScheme="blue"
                size="md"
                px={8}
                // mt={8}
                onClick={handleDownloadReport}
              >
                Download XLS Report
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Stack>

      {errorMessage && (
        <Box mt={2} color="red.500">
          <Text>{errorMessage}</Text>
        </Box>
      )}

      {/* Popup Modal */}
      <Modal isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <VStack>
                <Text mt={4}>Fetching data... Please wait.</Text>
              </VStack>
            ) : (
              <Text>
                The report has been generated successfully. Please click “Download” to proceed.
              </Text>
            )}
          </ModalBody>
          <ModalFooter
            sx={{ background: "transparent", justifyContent: "space-around" }}
          >
            {!isLoading && (
              <>
              <Button
                sx={{ background: "#000", color: "#fff" }}
                onClick={() => setIsPopupOpen(false)}
                >
                Cancel
              </Button>
              <Button
                sx={{ background: "#000", color: "#fff" }}
                onClick={getImpactStoriesReport}
                >
                Download
              </Button>
                </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default RadioTabone;
