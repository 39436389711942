import axios from 'axios';

export default axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`
  
});

// apiClient.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       window.location.href = '/login';
//     }
//     return Promise.reject(error);
//   }
// );

