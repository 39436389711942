import React, { useState } from 'react'
import Styles from './pageInfo.module.css'
import { Text, Heading, Stack, Image } from '@chakra-ui/react'
import parse from 'html-react-parser';

const PageInfo = (props) => {
    return (
        <Stack spacing={6} mb={'10px'}>
            <Heading as='h2' fontSize={"18px"} fontWeight={"600"} textTransform={"capitalize"}>
                {props.pageInfo && props.pageInfo.prop_label}
            </Heading>
            {/* {(props?.pageInfo && props?.pageInfo.banner) && <Image src={props?.pageInfo && props?.pageInfo.banner} alt={props.pageInfo && props.pageInfo.prop_label}/>} */}
            {props?.pageInfo && props.pageInfo.description != null  ? parse(props.pageInfo && props.pageInfo.description) : ''}
        </Stack>
    )
}

export default PageInfo