import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../notifications.module.css'
import API from '../../../../../Services/API';
import { IconButton } from '@chakra-ui/react'
import { EditIcon, DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Card, Heading, Text,Button,Tooltip} from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
const DeleteComponent = ({id, getNotification}) => {
  const token = window.localStorage.getItem("accessToken")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState()
  const [message, setMessage] = useState(null)

  const deleteMetric = async () => {
    setMessage(null)
    setIsLoading(true)
    API.delete(`/custom-notification`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        id: id, // Replace `id` with your actual ID value
      },
    }).then(response => {
      getNotification()
      setIsLoading(false)
      onClose()
      setMessage('Notification have been successfully deleted.')
    }).catch(error => {
      setMessage('Failed to update aspiration. Please try again.')
    });
  }
  
  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}
      {
        message ?<ErrorWidget message={message} /> : null
      }
      <Tooltip id={id} label="Delete Notification" aria-label="Delete Notification"> 
        <IconButton
          variant='solid'
          aria-label='Done'
          fontSize='15px'
          icon={<DeleteIcon />}
          ml={'5px'}
          className={Styles.edit} id={id}
          onClick={onOpen}
        />
      </Tooltip>
            
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay className={Styles.overLay} />
        <ModalContent className={Styles.contentZindex} maxW={'320px'} position={'relative'}>
          <ModalCloseButton />
          <ModalBody position={'relative'} top={'50%'} transform={'translateY(30%)'} textAlign={'center'} fontWeight={'600'}>
            <Text className={Styles.contentInfo}>Are you sure you want to delete this notification ?</Text>
          </ModalBody>
          <ModalFooter backgroundColor={'transparent'} className={Styles.modelFooter} justifyContent={'center'}>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button className={Styles.confirmButton} id={id} onClick={deleteMetric} backgroundColor={'black'} color={'#ffffff'}>
              Delete <DeleteIcon ml={'5px'} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteComponent