import React, { useState, useEffect, useContext } from 'react';
import Styles from './metricsettings.module.css';
import Select from 'react-select';
import API from '../../../../../Services/API';
import { useParams } from 'react-router-dom';
import {
  Box,
  SimpleGrid,
  FormControl,
  FormLabel,
  Switch,
  Button,
} from '@chakra-ui/react';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Widgets/ErrorWidget/ErrorWidget';
import { EditContext } from '../../../../../Context/BreadcrumbsContext';

const MetricSettings = ({ editable }) => {
  const token = window.localStorage.getItem('accessToken');
  const { metricId } = useParams();
  const { hasAllPermission } = useContext(EditContext);

  // State variables
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [metric, setMetric] = useState(null);
  const [strategyOptions, setStrategyOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [selectedDataType, setSelectedDataType] = useState(null);
  const [selectedMetricType, setSelectedMetricType] = useState(null);
  const [selectedAggregate, setSelectedAggregate] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isReportEx, setIsReportEx] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [isElr, setIsElr] = useState(false);
  const [isMap, setIsMap] = useState(false);
  const [user, setUser] = useState({})

  // Data arrays
  const dataTypes = [
    { value: 'percent', label: 'Percent' },
    { value: 'number', label: 'Number' },
    { value: 'money', label: 'Money' },
  ];

  const metricTypes = [
    { value: 'operational', label: 'Operational' },
    { value: 'financial', label: 'Financial' },
    { value: 'impact', label: 'Impact' },
  ];

  const aggregateTypes = [
    { value: 'sum', label: 'Sum' },
    { value: 'avg', label: 'Avg' },
    { value: 'max', label: 'Max' },
    { value: 'last', label: 'Last' },
  ];

  // Fetch data on mount
  useEffect(() => {
    setIsLoading(true);
    API.get(`/get-specific-modules?metric_id=${metricId}`, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
    .then((response) => {
        setUser(response.data); // Set the response data into state
    })
    .catch(error => {
        setIsLoading(false); // Handle error, maybe set a message
    });

    const fetchMetricDetails = async () => {
      try {
        const metricResponse = await API.get(`/get-metric?metric_id=${metricId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const metricData = metricResponse.data[0];
        setMetric(metricData);
        setSelectedStrategy(metricData.strategy);
        setSelectedDataType(metricData.datatype);
        setSelectedMetricType(metricData.metrictype);
        setSelectedAggregate(metricData.aggregate);
        setSelectedTags(metricData.metric_tag || []);
        setIsReportEx(metricData.allow_export_externally);
        setIsPublic(metricData.cisco_workforce);
        setIsElr(metricData.elr);
        setIsMap(metricData.map);
      } catch (error) {
        console.error('Error fetching metric:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchStrategyOptions = async () => {
      try {
        const response = await API.get('/strategy', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStrategyOptions(
          response.data.map((strategy) => ({
            value: strategy.id,
            label: strategy.name,
          }))
        );
      } catch (error) {
        console.error('Error fetching strategy options:', error);
      }
    };

    const fetchTagOptions = async () => {
      try {
        const response = await API.get('/tag-detail/', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTagOptions(
          response.data.results.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))
        );
      } catch (error) {
        console.error('Error fetching tag options:', error);
      }
    };

    fetchMetricDetails();
    fetchStrategyOptions();
    fetchTagOptions();
  }, [metricId, token]);

  // Save settings
  const saveSettings = async () => {
    const tagIds = selectedTags.map((tag) => tag.id);
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('id', metricId);
      formData.append('strategy', selectedStrategy);
      formData.append('datatype', selectedDataType);
      formData.append('metrictype', selectedMetricType);
      formData.append('aggregate', selectedAggregate);
      formData.append('allow_export_externally', isReportEx);
      formData.append('cisco_workforce', isPublic);
      formData.append('elr', isElr);
      formData.append('map', isMap);
      formData.append('metric_tag', JSON.stringify(tagIds));
      if(user.superuser || user.action_office_user){
        formData.append('approval_status', 'approved');
      }else{
        formData.append('approval_status', 'pending');
      }

      await API.put(`/metric`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMessage('Successfully saved Metric Settings');
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Reusable switch component
  const SwitchField = ({ id, label, value, onChange }) => (
    <Box>
      <FormControl as={SimpleGrid} columns={{ base: 2 }}>
        <FormLabel fontWeight="bold" htmlFor={id}>
          {label}
        </FormLabel>
        <Switch id={id} isChecked={value} onChange={onChange} isDisabled={!editable} />
      </FormControl>
    </Box>
  );

  const handleChange = (selected) => {
    const updatedSelectedTags = selected
      ? selected.map((item) => ({
          id: item.value,
          name: item.label,
        }))
      : [];
    setSelectedTags(updatedSelectedTags);
  };

  const selectedTagValues = selectedTags.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));

  

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {message && <ErrorWidget message={message} />}
      <FormControl>
        <SimpleGrid columns={[1, 2, 3, 4]} spacing="20px" mt="10px">
          <Box>
            <FormLabel fontWeight="bold" className={Styles.metricFont}>
              Strategy
            </FormLabel>
            <Select
              options={strategyOptions}
              value={strategyOptions.find((option) => option.value === selectedStrategy)}
              onChange={(e) => setSelectedStrategy(e.value)}
              isDisabled={!editable}
            />
          </Box>
          <Box>
            <FormLabel fontWeight="bold" className={Styles.metricFont}>
              Type of Data
            </FormLabel>
            <Select
              options={dataTypes}
              value={dataTypes.find((option) => option.value === selectedDataType)}
              onChange={(e) => setSelectedDataType(e.value)}
              isDisabled={!editable}
            />
          </Box>
          <Box>
            <FormLabel fontWeight="bold" className={Styles.metricFont}>
              Type of Metric
            </FormLabel>
            <Select
              options={metricTypes}
              value={metricTypes.find((option) => option.value === selectedMetricType)}
              onChange={(e) => setSelectedMetricType(e.value)}
              isDisabled={!editable}
            />
          </Box>
          <Box>
            <FormLabel fontWeight="bold" className={Styles.metricFont}>
              Aggregate Function
            </FormLabel>
            <Select
              options={aggregateTypes}
              value={aggregateTypes.find((option) => option.value === selectedAggregate)}
              onChange={(e) => setSelectedAggregate(e.value)}
              isDisabled={!hasAllPermission || !editable}
            />
          </Box>
          <Box>
            <FormLabel fontWeight="bold">Select Tags</FormLabel>
            {/* <Select
              isMulti
              options={tagOptions}
              value={tagOptions.filter((tag) => selectedTagValues.includes(tag.value))}
              onChange={(selected) => setSelectedTags(selected.map((item) => item.value))}
              isDisabled={!editable}
            /> */}
            <Select
              isMulti
              options={tagOptions}
              value={selectedTagValues}
              onChange={handleChange}
              isDisabled={!editable}
            />
          </Box>
        </SimpleGrid>
        <SimpleGrid columns={[1, 2, 3, 4]} spacing="20px" mt="10px">
          <SwitchField id="isReportEx" label="Report Externally" value={isReportEx} onChange={(e) => setIsReportEx(e.target.checked)} />
          <SwitchField id="isPublic" label="Cisco Workforce" value={isPublic} onChange={(e) => setIsPublic(e.target.checked)} />
          <SwitchField id="isElr" label="Executive Leadership Review" value={isElr} onChange={(e) => setIsElr(e.target.checked)} />
          <SwitchField id="isMap" label="Map" value={isMap} onChange={(e) => setIsMap(e.target.checked)} />
        </SimpleGrid>
        {editable && (
          <Box mt="20px">
            <Button onClick={saveSettings} className={Styles.buttonNew}>
              Save
            </Button>
          </Box>
        )}
      </FormControl>
    </>
  );
};

export default MetricSettings;