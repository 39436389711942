import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './impactmetrics.module.css'
import API from '../../../../Services/API';
import {Heading, SimpleGrid, Box, Flex, Switch, Card, CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import AreaChartComponent from '../../../Modules/Charts/AreaChartComponent';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import TabLoader from '../../../Widgets/CommonWidgets/TabLoader/TabLoader';
import { InfoOutlineIcon } from '@chakra-ui/icons'
import parse from 'html-react-parser';
import NumberFormat from '../../../Modules/NumberFormat/NumberFormat';

import DeleteMetric from './DeleteMetric/DeleteMetric';
import AddImpactMetric from './AddImpactMetric/AddImpactMetric';

const ImpactMetrics = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading]  = useState(false)
  const [impactMetrics, setImpactMetrics] = useState()
  const {id} = useParams();
  const [message, setMessage] = useState()
  const [pillarId, setPillarId] = useState()
  const [newLoaderLoading, setNewLoaderLoading]  = useState(null)
  const [deleteMessage, setDeleteMessage]  = useState(false)
  const [matchedData, setMatchedData] = useState([]);
  const [metricLength, setMetricLength] = useState(1);
  const [matchedDataLength, setMatchedDataLength] = useState(1);

  useEffect(() => {
    setIsLoading(true)
    API.get(`/pillar?module_id=${id}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      setPillarId(response.data.id)
      API.get(`/action?pillar_id=${response.data.id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((actions) => {
        setPillarId(response.data.id)
        if(props.selectedType !== null){
          API.get(`/metric-filter?fiscal_${props.selectedType}=${props.selectedValue}&query=impact&pillar_id=${response.data.id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }   
          }).then(metrics=>{
            const matchedDataArray = actions.data && actions.data.filter(data1Obj =>
              metrics.data && metrics.data.some(dataObj => dataObj.action_id === data1Obj.id)
            );
            setMatchedData(matchedDataArray);
            setImpactMetrics(metrics.data)
            setMetricLength(metrics.data.length)
            setMatchedDataLength(matchedDataArray.length)
            setIsLoading(false)
          }).catch(error=>{
            setIsLoading(false)
          })
        }else{
          API.get(`/metric?pillar_id=${response.data.id}&query=impact`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(metrics=>{
            const matchedDataArray = actions.data && actions.data.filter(data1Obj =>
              metrics.data && metrics.data.some(dataObj => dataObj.action_id === data1Obj.id)
            );
            setMatchedData(matchedDataArray);
            setImpactMetrics(metrics.data)
            setIsLoading(false)
            setMetricLength(metrics.data.length)
            setMatchedDataLength(matchedDataArray.length)
          }).catch(error=>{
            setIsLoading(false)
          })
        }
      }).catch(error=>{
      })
    })
  },[props.pillarId, props.selectedType, props.selectedValue, id, token])
  
  const handleFlip = (event) => {
    $(event.target).closest('.parentBox').addClass('flipped')
  };
  const handleFlipBack = (event) => {
    $(event.target).closest('.parentBox').removeClass('flipped')
  };
  const currentYear = new Date().getFullYear();
  
  const getUpdate = (impactMetric) => {
      API.get(`/sio-pillar`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(actions=>{
        if(props.selectedType !== null){
          API.get(`/metric-filter?query=impact&pillar_id=${pillarId}&fiscal_${props.selectedType}=${props.selectedValue}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }   
          }).then(metrics=>{
            const matchedDataArray = actions.data && actions.data.filter(data1Obj =>
              metrics.data && metrics.data.some(dataObj => dataObj.pillar_id === data1Obj.id)
            );
            setMatchedData(matchedDataArray);
            setImpactMetrics(metrics.data)
            setMetricLength(metrics.data.length)
            setMatchedDataLength(matchedDataArray.length)
            setIsLoading(false)
          }).catch(error=>{
            setIsLoading(false)
          })
        }else{
          API.get(`/metric?pillar_id=${pillarId}&query=impact`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(metrics=>{
            const matchedDataArray = actions.data && actions.data.filter(data1Obj =>
              metrics.data && metrics.data.some(dataObj => dataObj.pillar_id === data1Obj.id)
            );
            setMatchedData(matchedDataArray);
            setImpactMetrics(metrics.data)
            setMetricLength(metrics.data.length)
            setMatchedDataLength(matchedDataArray.length)
            setIsLoading(false)
          }).catch(error=>{
            setIsLoading(false)
          })
        }
      })
      
      //setImpactMetrics(impactMetric)
      setMessage(true)
    }

  const customSort = (a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    const numA = parseInt(nameA.match(/\d+/), 10);
    const numB = parseInt(nameB.match(/\d+/), 10);
    if (!isNaN(numA) && !isNaN(numB) && numA !== numB) {
      return numA - numB;
    }
    return nameA.localeCompare(nameB);
  };

  return (
    <>
      {isLoading ? <TabLoader /> : null}
      {props.isLoading && props.isLoading ? <TabLoader /> : null}

      {isLoading ? <TabLoader /> : null}
      {newLoaderLoading ? <LoaderSpinner /> : null}
      {deleteMessage ? <Box className='colorBlue'> Deleted metric successfully </Box> : null}
      {metricLength < 1 ? <Box fontSize={'14px'}> We couldn’t find any data matching your criteria. Please try again with different filters or check back later. </Box> : null}
      
      {
        <Box maxW={'940px'}>
          {
            props.editable ? 
              <Box>
                <AddImpactMetric pillarId={pillarId} getUpdate={getUpdate} /> 
              </Box>
            : null
          }
          <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
            {
              impactMetrics && impactMetrics.map(metric => 
                <Box className={Styles.commonElementStyle} backgroundColor={metric.color_picker}>
                  {
                    props.editable ? <DeleteMetric pillarId={pillarId} id={metric.id} getUpdate={getUpdate} /> : null
                  }
                  <Link to={`impactmetrics/${metric.id}/${encodeURIComponent(metric.name)}`}>
                    <Card maxW='100%' backgroundColor={'transparent'} p={'0px'} className={Styles.customCard}>
                      <CardHeader p={'0px'}>
                        <Flex spacing='4'>
                          <Flex flex='1' gap='2' alignItems='center' flexWrap='wrap'>
                            <Box display={'flex'}>
                              {
                                metric.image ? <Image src={metric.image} w={'64px'} height={'64px'} alt={metric.name}/> : null 
                              }
                              <Heading className={Styles.customHeadingNumber}  ml={'15px'}>{metric.key_fact !== null ? metric.key_fact : 0}</Heading>
                              <Box className='clearfix'> </Box>
                            </Box>
                            <Text className={Styles.customPara} width={'100%'}>{metric.name}</Text>
                          </Flex>
                        </Flex>
                      </CardHeader>
                    </Card>
                  </Link>
                </Box>
              )
            }
          </SimpleGrid>
        </Box>
      }
    </>
  )
}

export default ImpactMetrics