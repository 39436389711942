import React, { useState, useRef, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Heading, Card, CardBody, Box, Text, Button, Avatar, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton, Spinner } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import Styles from '../team.module.css';
import API from '../../../../Services/API';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import userPlaceholder from '../../../../../assets/images/userPlaceholder.svg';
import ErrorWidget from '../../../Widgets/ErrorWidget/ErrorWidget';

const TeamList = ({ id, name, image, jobTitle, editable, pillarId, getUpdatedTeam }) => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const { category } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    const deleteTeam = async () => {
        setIsLoading(true)
        API.delete(`/team-member`, {data: { member_id: id }}, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setSuccessMessage("Successfully deleted");
            API.get(`/team-member?pillar_id=${pillarId}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }   
            }).then(response => {
                getUpdatedTeam(response.data)
                setIsLoading(false)
                onClose()
            }
            ).catch(error=>{
                setIsLoading(false)
                onClose()
                setSuccessMessage("Failed to delete");
            })
        })
    }
        
    return (
        <>
            {isLoading && <LoaderSpinner />}
            {
                successMessage && <ErrorWidget message={successMessage} />
            }
            <Box>
                {editable && (
                    <Box className={Styles.deleteCont}>
                        <Button className={Styles.deleteButton} aria-label="Delete team member" onClick={onOpen}>
                            <DeleteIcon color={'#00a0da'} />
                        </Button>
                    </Box>
                )}
                <Link to={`team/${id}/${name}`}>
                    <Card padding={'0'} w={'100%'} className={editable ? Styles.customCardPo : ''}>
                        <CardBody padding={'0'}>
                            <Box className={Styles.clearfix}>
                                <Box float={'left'} maxWidth={'86px'} width={'31%'} className={Styles.imgContainer}>
                                    <Avatar name={name} src={image || userPlaceholder} height={'106px'} width={'100%'} borderRadius={'0'} />
                                </Box>
                                <Box float={'left'} p={'5px'} w={'69%'} maxWidth={'196px'}>
                                    <Text as={'span'} className={Styles.teamLink}> {name} </Text>
                                    <Text py='2' className={Styles.designation}>{jobTitle}</Text>
                                </Box>
                            </Box>
                        </CardBody>
                    </Card>
                </Link>
                <AlertDialog
                    motionPreset='slideInBottom'
                    leastDestructiveRef={cancelRef}
                    isOpen={isOpen}
                    isCentered
                    closeOnOverlayClick={false}
                    onClose={onClose}
                >
                    {isLoading && (
                        <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' className={Styles.spinner} />
                    )}
                    <AlertDialogOverlay className={Styles.overLay}>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Delete {name}
                            </AlertDialogHeader>
                            <AlertDialogBody mb={'20px'}>
                                Are you sure? You can't undo this action afterwards.
                            </AlertDialogBody>
                            <AlertDialogCloseButton />
                            <AlertDialogFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                                <Button className={Styles.cancel} ref={cancelRef} onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button colorScheme='blue' onClick={deleteTeam} ml={3} title={name}>
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            </Box>
        </>
    );
};

export default TeamList;
