import React, { useState, useEffect } from 'react';
import { Button, FormControl, FormLabel, Input, Box, Text, SimpleGrid, Switch } from '@chakra-ui/react';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import API from '../../../../../../Services/API';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ErrorWidget from '../../../../../Widgets/ErrorWidget/ErrorWidget';

const CreateAspiration = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { metricId } = useParams();
    const [fiscalYearList, setFiscalYearList] = useState([]);
    const [fiscalQtrList, setFiscalQtrList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [allow, setAllow] = useState(false);
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [selectedFiscalYearList, setSelectedFiscalYearList] = useState("");
    const [formData, setFormData] = useState({
        name: props.title || '',
        value: '',
        targetFiscalYear: '',
        targetFiscalQuarter: '',
    });
    const [validationError, setValidationError] = useState({
        name: '',
        value: '',
        targetFiscalYear: '',
        targetFiscalQuarter: '',
    });
    const [user, setUser] = useState({});

    useEffect(() => {
        API.get(`/fiscal-year-detail`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            setFiscalYearList(response.data[0].fiscalyear.map(year => ({ value: year.id, label: year.name })));
            setFiscalQtrList(response.data[1].fiscalquarter.map(qtr => ({ value: qtr.id, label: qtr.name })));
        }).catch(() => setIsLoading(false));

        API.get(`/get-specific-modules?metric_id=${metricId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => setUser(response.data)).catch(() => setIsLoading(false));
    }, [metricId, token]);

    const validateForm = () => {
        const errors = {
            name: '',
            value: '',
            targetFiscalYear: '',
            targetFiscalQuarter: '',
        };
        let valid = true;

        if (!formData.name) {
            errors.name = 'Name is required';
            valid = false;
        }
        if (!formData.value) {
            errors.value = 'Value is required';
            valid = false;
        }
        if (!formData.targetFiscalYear && !formData.targetFiscalQuarter) {
            errors.targetFiscalYear = 'Target Fiscal Year or Quarter is required';
            valid = false;
        }

        setValidationError(errors);
        return valid;
    };

    const newAspiration = () => {
        if (!validateForm()) return;

        setIsLoading(true);
        const body = {
            metric: metricId,
            name: formData.name,
            value: parseInt(formData.value),
            targetfiscalyear: formData.targetFiscalYear?.value,
            targetfiscalquarter: formData.targetFiscalQuarter?.value,
            allow_export_externally: allow,
            approval_status: (user.superuser || user.action_office_user) ? "approved" : "pending",
            position: user.superuser ? 'superuser'
                : user.action_office_user ? 'action_office_user'
                : user.comms_user ? 'comms_user'
                : user.elt_user ? 'elt_user'
                : user.position || '',
            position_of_user: (user.position === 'pillar_owner' || user.position === 'action_owner') ? 'owner'
                : (user.position === 'action_contributor' || user.position === 'pillar_contributor') ? 'contributor' : ''
        };

        API.post(`/metric-target`, body, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(() => {
            API.get(`/metric-target?metric_id=${metricId}`)
                .then(response1 => {
                    props.getUpdatedAspiration(response1.data);
                    setMessage(true);
                    setIsLoading(false);
                    setIsPaneOpen(false);
                    resetForm();
                }).catch(() => setIsLoading(false));
        }).catch(() => setIsLoading(false));
    };

    const resetForm = () => {
        setFormData({
            name: props.title || '',
            value: '',
            targetFiscalYear: '',
            targetFiscalQuarter: '',
        });
        setValidationError({
            name: '',
            value: '',
            targetFiscalYear: '',
            targetFiscalQuarter: '',
        });
        setSelectedFiscalYearList("");
        setAllow(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleFiscalYearChange = (selectedOption) => {
        setFormData(prev => ({
            ...prev,
            targetFiscalYear: selectedOption,
            targetFiscalQuarter: null
        }));
        setSelectedFiscalYearList(selectedOption ? selectedOption.label.substring(0, 4) : "");
    };

    const handleFiscalQuarterChange = (selectedOption) => {
        setFormData(prev => ({
            ...prev,
            targetFiscalQuarter: selectedOption
        }));
    };

    const handleSwitchChange = (event) => setAllow(event.target.checked);

    const sortedOptionsFiscalQtrList = fiscalQtrList?.sort((a, b) => {
        const parseLabel = label => label.match(/FY(\d+)\sQ(\d+)/)?.slice(1).map(Number) || [0, 0];
        const [yearA, quarterA] = parseLabel(a.label);
        const [yearB, quarterB] = parseLabel(b.label);
        return yearA !== yearB ? yearB - yearA : quarterB - quarterA;
    });

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message="Created aspiration successfully" />}
            <Button float="right" className="buttonPrimary" mb="10px" onClick={() => setIsPaneOpen(true)}>New Aspiration</Button>
            <SlidingPane
                isOpen={isPaneOpen}
                title="Create Aspiration"
                onRequestClose={() => setIsPaneOpen(false)}
                width="50%"
            >
                <SimpleGrid columns={[1, 2]} spacing="20px" mt="10px">
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Name</FormLabel>
                            <Input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                mb="15px"
                                className={validationError.name ? 'redBorder' : ''}
                            />
                            <Text color="red">{validationError.name}</Text>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Value</FormLabel>
                            <Input
                                type="text"
                                name="value"
                                value={formData.value}
                                onChange={handleInputChange}
                                mb="15px"
                                className={validationError.value ? 'redBorder' : ''}
                            />
                            <Text color="red">{validationError.value}</Text>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Target Fiscal Year</FormLabel>
                            <Select
                                options={fiscalYearList}
                                value={formData.targetFiscalYear}
                                onChange={handleFiscalYearChange}
                                placeholder="Select fiscal year"
                                className={validationError.targetFiscalYear ? 'redBorder' : ''}
                            />
                            <Text color="red">{validationError.targetFiscalYear}</Text>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight="bold">Target Fiscal Quarter</FormLabel>
                            <Select
                                options={sortedOptionsFiscalQtrList?.filter(qtr => selectedFiscalYearList && qtr.label.startsWith(selectedFiscalYearList))}
                                value={formData.targetFiscalQuarter}
                                onChange={handleFiscalQuarterChange}
                                placeholder="Select fiscal quarter"
                                className={validationError.targetFiscalQuarter ? 'redBorder' : ''}
                            />
                            <Text color="red">{validationError.targetFiscalQuarter}</Text>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display="flex" alignItems="center" mt="0px">
                            <FormLabel htmlFor="share_data_externally" mb="0" fontWeight="bold">Share Data Externally</FormLabel>
                            <Switch id="share_data_externally" mt="10px" onChange={handleSwitchChange} />
                        </FormControl>
                    </Box>
                </SimpleGrid>
                <Box>
                    <FormControl>
                        <Button colorScheme="blue" onClick={newAspiration} mt="20px" float="right">Add Aspiration</Button>
                    </FormControl>
                </Box>
            </SlidingPane>
        </>
    );
};

export default CreateAspiration;
