import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Styles from './mytasks.module.css'
import { Box, Button, Card, CardBody, Link } from '@chakra-ui/react'
import BreadCrumbs from '../../Components/Widgets/BreadCrumbs/BreadCrumbs';


import { EditContext } from "../../Context/BreadcrumbsContext";
import Faqs from '../Help/FAQs/Faqs';
import AboutSio from '../Help/AboutSio/AboutSio';
import Training from '../Help/Training/Training';
import API from '../../Services/API';
import { useNavigate } from 'react-router-dom';
const MyTasks = () => {
    const navigate = useNavigate()
    const token = window.localStorage.getItem("accessToken")
    const [editable, setEditable] = useState();
    const geteditStatus = (isEdit) =>{
      setEditable(isEdit)
    }
    const { edit, setEdit, currentHelpTab,setHelpTab } = useContext(EditContext);
    const [activeTab, setActiveTab] = useState(currentHelpTab);
    const renderTabContent = () => {
        switch (activeTab) {
            case 'FAQs': return <Faqs />;
            case 'About SIIO': return <AboutSio />;
            case 'Training Guide Training Videos': return <Training />;
            default : return null;
        }
    };

    useEffect(()=>{
      setHelpTab(activeTab)
    },[activeTab])


    return (
      <>
        <BreadCrumbs geteditStatus={geteditStatus} title={"Help"} />
        <Box>
          <Card>
            <CardBody>
              <Box>
                <Button
                  onClick={() => {
                    setActiveTab("FAQs")

                  }}
                  className={
                    activeTab === "FAQs"
                      ? `${Styles.active} ${Styles.tabStyle}`
                      : `${Styles.tabStyle}`
                  }
                >
                  FAQs
                </Button>
                <Button
                  onClick={() => setActiveTab("About SIIO")}
                  className={
                    activeTab === "About SIIO"
                      ? `${Styles.active} ${Styles.tabStyle}`
                      : `${Styles.tabStyle}`
                  }
                >
                  About SIIO
                </Button>
                <Button
                  onClick={() => setActiveTab("Training Guide Training Videos")}
                  className={
                    activeTab === "Training Guide Training Videos"
                      ? `${Styles.active} ${Styles.tabStyle}`
                      : `${Styles.tabStyle}`
                  }
                >
                  Training Guides & Videos
                </Button>
              </Box>
              <Box className={Styles.tabContent}>{renderTabContent()}</Box>
            </CardBody>
          </Card>
        </Box>
      </>
    );
}

export default MyTasks

// import React, { useState, useEffect, useContext } from 'react';
// import { Box, Button, Card, CardBody } from '@chakra-ui/react';
// import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
// import BreadCrumbs from '../../Components/Widgets/BreadCrumbs/BreadCrumbs';
// import { EditContext } from "../../Context/BreadcrumbsContext";
// import Faqs from '../Help/FAQs/Faqs';
// import AboutSio from '../Help/AboutSio/AboutSio';
// import Training from '../Help/Training/Training';
// import API from '../../Services/API';
// import Styles from './mytasks.module.css';

// const MyTasks = () => {
//     const token = window.localStorage.getItem("accessToken");
//     const [editable, setEditable] = useState();
//     const { edit, setEdit } = useContext(EditContext);
//     const [activeTab, setActiveTab] = useState('Training Guide Training Videos');
//     const navigate = useNavigate(); // Initialize navigate function

//     const geteditStatus = (isEdit) => {
//         setEditable(isEdit);
//     };

//     const renderTabContent = () => {
//         switch (activeTab) {
//             case 'FAQs': return <Faqs />;
//             case 'About SIIO': return <AboutSio />;
//             case 'Training Guide Training Videos': return <Training />;
//             default: return null;
//         }
//     };

//     useEffect(() => {
//         API.get(`/project-modules`, {
//             headers: {
//                 'Authorization': 'Bearer ' + token,
//             },
//         }).then(response => {
//             console.log("responseheltab",response.data.filter((a) => 
//                 ["About SIIO", "FAQs", "Training Guide & Training Videos"].some(name => a.name.includes(name))
//               )
//               );
//             // console.log("responseheltab", response.data.filter((a)=>a.name.includes["About SIIO","FAQs","Training Guide & Training Videos"]));
//         }).catch(error => {
//             if (error.response.data.code === 'token_not_valid') {
//                 window.localStorage.removeItem('accessToken');
//                 window.localStorage.removeItem('refreshToken');
//                 window.localStorage.removeItem('user');
//                 window.location.href = '/login';
//             }
//         });
//     }, []);

//     // Function to handle tab click and update URL
//     const handleTabClick = (tab) => {
//         setActiveTab(tab);
//         // to={`/${item.category}/${item.unique_name.replace(/\s+/g, '').toLowerCase()}/${item.id}/${item.name}`
//         // Update the URL to reflect the selected tab
//         navigate(`/help/${tab.toLowerCase().replace(/\s+/g, '-')}`);
//     };

//     return (
//         <>
//             <BreadCrumbs geteditStatus={geteditStatus} title={'Help'} />
//             <Box>
//                 <Card>
//                     <CardBody>
//                         <Box>
//                             <Button onClick={() => handleTabClick('FAQs')} className={activeTab === 'FAQs' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>
//                                 FAQs
//                             </Button>
//                             <Button onClick={() => handleTabClick('About SIIO')} className={activeTab === 'About SIIO' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>
//                                 About SIIO
//                             </Button>
//                             <Button onClick={() => handleTabClick('Training Guide Training Videos')} className={activeTab === 'Training Guide Training Videos' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>
//                                 Training Guide Training Videos
//                             </Button>
//                         </Box>
//                         <Box className={Styles.tabContent}>
//                             {renderTabContent()}
//                         </Box>
//                     </CardBody>
//                 </Card>
//             </Box>
//         </>
//     );
// };

// export default MyTasks;
