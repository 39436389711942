import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Styles from '../viewpost.module.css'
import $ from 'jquery'
import API from '../../../../../Services/API';
import Select from 'react-select';
import { Heading, Box, Image, Text, Input, Textarea, Divider, Button, Card, CardHeader, CardBody, CardFooter, Stack, ButtonGroup, Grid, GridItem, StackDivider  } from '@chakra-ui/react'
import { Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton,} from '@chakra-ui/react'
import {FormControl, FormLabel, FormErrorMessage, FormHelperText, Badge } from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import { EditContext } from '../../../../../Context/BreadcrumbsContext';

const Tags = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(false)
    const [pillar, setPillar] = useState()
    const [selectedOptions, setSelectedOptions] = useState()
    const [message, setMessage] = useState(null)
    
  const {hasTagApplyPermission ,user} = useContext(EditContext);
    
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        API.get(`/tag-detail/`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            const sortedData = response && response?.data?.results.sort((a, b) => 
                a.name.localeCompare(b.name)
              );
            setPillar(sortedData && sortedData)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[])
    
    const options = pillar && pillar.map(item =>
        ({label: item.name, value: item.id})
    )

    const defaultSelectedOptions = props.selectedOptions && props.selectedOptions.map(item =>
        ({label: item.name, value: item.id})
    )

    const selectOption = (event) =>{
        setSelectedOptions(event.map(option => option.value));
    }

    const addOptions = async () => {
        setMessage(null);
        setIsLoading(true);
    
        try {
            const formData = new FormData();
            formData.append('blog_id', parseInt(props.id));
            formData.append('blog_tag', JSON.stringify(selectedOptions));
            // formData.append(
            //     'approval_status',
            //    "pending"
            // );
            formData.append(
                "approval_status",
                (Array.isArray(selectedOptions) && selectedOptions?.length===0) ? "approved" : "pending"
              );
            // Update blog with new data
            const updateResponse = await API.put(`/blog`, formData, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            if (updateResponse && updateResponse.status !== 201) {
                throw new Error('Failed to update the blog.');
            }
    
            // Fetch updated blog data
            const getResponse = await API.get(`/blog?id=${props.id}`, {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            });
            
            if (getResponse && getResponse.status !== 200) {
                throw new Error('Failed to fetch the updated blog data.');
            }
    
            // Update the UI with the fetched data
            props.getUpdated(getResponse.data[0]);
            setMessage('Changes updated successfully');
        } catch (error) {
           // Handle errors from the PUT request
        //    console.log("Error updating blog:", error.response.data.msg);
           setMessage(error &&  error.response &&  error.response.data && error.response.data.msg);
           setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };
    
    return (
        <>
            { 
                isLoading ? <LoaderSpinner />: null
            }
             {message && message ? <ErrorWidget message={message && message} /> : null}
            <Box>
                <Heading className={Styles.sideBarHeading}>
                    Tags
                </Heading>
                <Text p='0' className={Styles.sideBarText}>
                    {
                        (props.editable &&
                            (hasTagApplyPermission || parseInt(user?.id) === (props?.currentBlog[0]?.created_by))
                          ) ?
                            <>
                                <Select
                                    placeholder="Select Tags"
                                    aria-label='Select Tags'
                                    options={options}
                                    isMulti
                                    defaultValue={defaultSelectedOptions}
                                    onChange={selectOption}
                                />
                                <Button  background={'#00aae0'} color={'#ffffff'} mt={'5px'} onClick={addOptions}> Save </Button>
                            </>
                        : <>
                            {
                                props.selectedOptions && props.selectedOptions.map(item => 
                                    <Tag
                                        borderRadius='full'
                                        variant='solid'
                                        background={'#d4d4d8'}
                                        color={"#000000"}
                                        mr={'5px'}
                                        mb={'5px'}
                                    >
                                        <TagLabel>{item.name}</TagLabel>
                                    </Tag>
                                )
                            }
                    </>
                    }
                    
                </Text>
            </Box>
        </>
    )
}

export default Tags