import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import $ from 'jquery';
import Styles from './editaspiration.module.css';
import { IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, FormControl, FormLabel, Input, Text, Button, Switch } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import API from '../../../../Services/API';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const EditAspiration = ({ aspiration, getAspiration }) => {
    const token = window.localStorage.getItem('accessToken');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [fiscalYearList, setFiscalYearList] = useState([]);
    const [fiscalQtrList, setFiscalQtrList] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
    const [allow, setAllow] = useState(aspiration.allow_export_externally || false);
    const [message, setMessage] = useState(null);

    const initialState = {
        name: aspiration.name || '',
        value: aspiration.value || '',
        fiscalYear: aspiration.target_fiscal_year_name || null,
        fiscalQuarter: aspiration.target_fiscal_quarter_name || null,
        allowExport: aspiration.allow_export_externally || false,
    };

    useEffect(() => {
        setIsLoading(true);
        API.get('/fiscal-year-detail', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((response) => {
            const yearList = response.data[0].fiscalyear.map((item) => ({ value: item.id, label: item.name }));
            const quarterList = response.data[1].fiscalquarter.map((item) => ({ value: item.id, label: item.name }));
            setFiscalYearList(yearList);
            setFiscalQtrList(quarterList);
            if (aspiration.targetfiscalyear) {
                const defaultYear = yearList.find((year) => year.label === aspiration.target_fiscal_year_name);
                setSelectedFiscalYear(defaultYear || null);
            }
            if (aspiration.targetfiscalquarter) {
                const defaultQtr = quarterList.find((qtr) => qtr.label === aspiration.target_fiscal_quarter_name);
                setSelectedFiscalQtr(defaultQtr || null);
            }
        })
        .catch((error) => {
            console.error('Failed to fetch fiscal data:', error);
        }).finally(() => setIsLoading(false));
    }, [token, aspiration.targetfiscalyear, aspiration.targetfiscalquarter]);

    const getChangedFields = () => {
        const currentState = {
            name: $("#aspiration_name").val(),
            value: parseInt($("#aspiration_value").val(), 10) || '',
            fiscalYear: selectedFiscalYear?.label || null,
            fiscalQuarter: selectedFiscalQtr?.label || null,
            allowExport: allow,
        };

        return Object.entries(currentState).reduce((acc, [key, value]) => {
            if (initialState[key] !== value) {
                acc[key] = { old: initialState[key], new: value };
            }
            return acc;
        }, {});
    };

    const updateAspiration = () => {
        setMessage(null);
        const changedFields = getChangedFields();

        if (Object.keys(changedFields).length === 0) {
            setMessage('No changes were made.');
            return;
        }

        setIsLoading(true);
        const body = {
            id: aspiration.id,
            name: $("#aspiration_name").val(),
            value: parseInt($("#aspiration_value").val(), 10),
            targetfiscalyear: parseInt(selectedFiscalYear?.value, 10),
            targetfiscalquarter: parseInt(selectedFiscalQtr?.value, 10),
            allow_export_externally: allow,
        };

        API.put(`/metric-target`, body, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(() => {
                getAspiration();
                setIsLoading(false);
                setIsOpen(false);
                setMessage('The aspiration details have been successfully updated.');
            })
            .catch((error) => {
                console.error('Failed to update aspiration:', error);
                setIsLoading(false);
            });
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <IconButton
                variant="solid"
                aria-label="Edit"
                icon={<EditIcon />}
                className={Styles.edit}
                onClick={() => setIsOpen(true)}
            />
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Aspiration</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl mb="15px">
                            <FormLabel fontWeight={'bold'}>Title</FormLabel>
                            <Input type="text" id="aspiration_name" defaultValue={aspiration.name} />
                        </FormControl>
                        <FormControl mb="15px">
                            <FormLabel fontWeight={'bold'}>Value</FormLabel>
                            <Input type="number" id="aspiration_value" defaultValue={aspiration.value} />
                        </FormControl>
                        <FormControl mb="15px">
                            <FormLabel fontWeight={'bold'}>Target Fiscal Year</FormLabel>
                            <Select
                                options={fiscalYearList}
                                value={selectedFiscalYear}
                                onChange={setSelectedFiscalYear}
                                placeholder="Select fiscal year"
                            />
                        </FormControl>
                        <FormControl mb="15px">
                            <FormLabel fontWeight={'bold'}>Target Fiscal Quarter</FormLabel>
                            <Select
                                options={fiscalQtrList}
                                value={selectedFiscalQtr}
                                onChange={setSelectedFiscalQtr}
                                placeholder="Select fiscal quarter"
                            />
                        </FormControl>
                        <FormControl display="flex" alignItems="center">
                            <FormLabel fontWeight={'bold'}>Share Data Externally</FormLabel>
                            <Switch isChecked={allow} onChange={(e) => setAllow(e.target.checked)} />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                        <Button onClick={updateAspiration} colorScheme="blue" ml={3}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default EditAspiration;
