import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../MetricData/metricdata.module.css'
import API from '../../../../Services/API';
import { IconButton } from '@chakra-ui/react'
import { EditIcon, DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Card, Heading, Text,Button,Tooltip} from '@chakra-ui/react'
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';
const DeleteComponent = ({id, getAspiration}) => {
  const token = window.localStorage.getItem("accessToken")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [metricData, setMetricData] = useState()
  const [isLoading, setIsLoading] = useState()
  const [message, setMessage] = useState(null)

  const deleteMetric = async () => {
    setMessage(null)
    setIsLoading(true)

    const body = {
      "id": id,
      "visible": false
    }

    API.put(`/metric-target`, body, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response => {
      getAspiration()
      setIsLoading(false)
      onClose()
      setMessage('The aspiration details have been successfully deleted.')
    }).catch(error=>{
      setMessage('Failed to update aspiration. Please try again.')
      setIsLoading(false)
    })
  }
  
  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}
      {
        message ?<ErrorWidget message={message} /> : null
      }
      <Tooltip id={id} label="Delete Aspiration" aria-label="Delete Aspiration"> 
        <IconButton
          variant='solid'
          aria-label='Done'
          fontSize='15px'
          icon={<DeleteIcon />}
          ml={'5px'}
          className={Styles.edit} id={id}
          onClick={onOpen}
        />
      </Tooltip>
            
      <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
        <ModalOverlay className={Styles.overLay} />
        <ModalContent className={Styles.contentZindex}>
          <ModalCloseButton />
          <ModalBody>
            <Text className={Styles.contentInfo}>Are you sure you want to delete it? This action cannot be reversed.</Text>
          </ModalBody>
          <ModalFooter className={Styles.modelFooter}>
            <Button mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button className={Styles.confirmButton} id={id} onClick={deleteMetric}>Confirm</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default DeleteComponent