import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Box, Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import API from '../../../../../../../Services/API';
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const AddNewMember = ({ pillarId, teamType, getUpdatedTeam, getErrorMessage, teamLeads }) => {
    const token = localStorage.getItem("accessToken");
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [buttonVisible, setButtonVisible] = useState(true);
    const [users, setUsers] = useState([]);
    const [showSubmit, setShowSubmit] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        API.get('/list-user', { headers: { Authorization: `Bearer ${token}` } })
            .then((response) => {
                setUsers(response.data);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    }, [token]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setButtonVisible(false);
    };

    const options = users.map(user => ({
        value: user.id,
        label: user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email,
    }));

    const handleSelect = (selectedOption) => {
        setShowSubmit(true);
        setSelectedUser(selectedOption.value);
    };

    const addTeamLead = () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('user', selectedUser);
        formData.append('action', pillarId);

        const roleMap = {
            lead: { role: 'lead' },
            member: { role: 'member' },
            eltsponsor: { role: 'member', position: 'elt_sponsor' }
        };

        Object.entries(roleMap[teamType] || {}).forEach(([key, value]) => formData.append(key, value));

        API.post('/team-member', formData, { headers: { Authorization: `Bearer ${token}` } })
            .then(() => API.get(`/team-member?action_id=${pillarId}`, { headers: { Authorization: `Bearer ${token}` } }))
            .then((response2) => {
                getUpdatedTeam(response2.data);
                setSelectedUser(null);
                setIsLoading(false);
            })
            .catch(error => {
                getErrorMessage(error.response?.data?.msg || 'Error adding team lead');
                setIsLoading(false);
            });
    };

    const filteredOptions = options.filter(option =>
        teamLeads && !teamLeads.some(lead => lead.member_name === option.label)
    );

    // const filteredOptions1 = options.filter(option =>
    //     team &&
    //     team.some(lead => lead.member_name === option.label && lead.level !== 'level1')
    // );
    
    const customStyles = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99999999,
        }),
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            <Box>
                {buttonVisible && (
                    <Button onClick={toggleDropdown} leftIcon={<AddIcon />} variant="outline">
                        Add
                    </Button>
                )}
                {isOpen && (
                    <Select
                        value={filteredOptions.find(option => option.value === selectedUser) || null}
                        options={filteredOptions}
                        onChange={handleSelect}
                        styles={customStyles}
                    />
                )}
            </Box>
            {showSubmit && (
                <Box mt={2}>
                    <Button onClick={addTeamLead} colorScheme="blue">Add Selected User</Button>
                </Box>
            )}
        </>
    );
};

export default AddNewMember;
