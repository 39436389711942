import React,{useEffect, useState} from 'react';
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  useDisclosure,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import API from '../../../../../Services/API';
import Styles from './ViewNotification.module.css';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { DeleteIcon, ViewIcon } from '@chakra-ui/icons';


const ViewNotification = ({ id, subject, message, notificaionReaded, getNotification }) => {
  const token = window.localStorage.getItem("accessToken")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState()
  const [successMessage, setSuccessMessage] = useState(null)

  useEffect(() => {
    if (isOpen) {
        setIsLoading(true)
        const payload = {
            id: id,
            notificaion_readed: true
        }
        API.put(`/custom-notification`, payload, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
        }).then(response => {
        getNotification()
        setIsLoading(false)
        }).catch(error => {
        });
    }
  }, [isOpen, id]);

  const markAsUnread = () => {
    setSuccessMessage(null)
    setIsLoading(true)
    const payload = {
        id: id,
        notificaion_readed: false
    }
    API.put(`/custom-notification`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }).then(response => {
      getNotification()
      setIsLoading(false)
      onClose()
      setSuccessMessage('Selected notification have been marked as unread.')
    }).catch(error => {
        setSuccessMessage('Selected notification have been not able to marked as unread.')
    });
  }

  return (
    <>
      <Tooltip id={id} label="Edit Notification" aria-label="Edit Notification" onClick={onOpen}> 
        <IconButton
          variant='solid'
          aria-label='Done'
          fontSize='15px'
          icon={<ViewIcon />}
          ml={'5px'}
          className={Styles.edit} id={id}
          onClick={onOpen}
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader id="notification-details-header">{subject}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {message ? parse(message) : 'No content available.'}
          </ModalBody>
          <ModalFooter backgroundColor={'transparent'}>
            <Button className={Styles.confirmButton} id={id} backgroundColor={'black'} color={'#ffffff'} onClick={onClose}> Ok</Button> 
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

ViewNotification.propTypes = {
  subject: PropTypes.string.isRequired,
  message: PropTypes.string,
};

ViewNotification.defaultProps = {
  message: '',
};

export default ViewNotification;
