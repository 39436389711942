import React, { useState, useEffect, useMemo, useRef } from 'react';
import API from '../../../Services/API';
import Styles from './regionmap.module.css';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { Heading, Card, CardBody, Box, Tooltip, FormControl, Text, Switch } from '@chakra-ui/react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import worldData from './world.geojson';
import Select from 'react-select';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import NumberFormat, { formatNumber } from './NumberFormat';
import MapFOrUSstate from '../../ProjectModule/GeographicImpact/MapFOrUSstate';
import { FullScreenLoader } from '../../ProjectModule/GeographicImpact/GeographicImpact';

const RegionMap = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const [countryData, setCountryData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const defaultScale = 130;
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showTooltip, setShowTooltip] = useState(false);
  const offset = { x: 10, y: 10 };

      const [fiscalYearList, setFiscalYearList] = useState([]);
      const [fiscalQtrList, setFiscalQtrList] = useState([]);
      const [selectedYearOption, setSelectedYearOption] = useState(null);
      const [selectedYearOption1, setSelectedYearOption1] = useState(null);
      const [selectedQtrOption, setSelectedQtrOption] = useState(null);
      const [selectedQtrOption1, setSelectedQtrOption1] = useState(null);
      const [selectedMetricValue, setSelectedMetricValue] = useState(null);
      const [metric, setMetric] = useState([]);
      
    const [sIOPillars, setSIOPillars] = useState([]);
      const [pillarID, setPillarID] = useState(null);
      const [selectionType, setSelectionType] = useState(null);
  
  const [regionData, setRegionData] = useState([]);
  const [regionParentData, setRegionParentData] = useState([]);
  const customStyles = {
    control: (provided) => ({
        ...provided,
        borderRadius: '12px',
        fontSize: '12px',
    }),
    option: (provided) => ({
        ...provided,
        fontSize: '12px',
    }),
};
  const handleZoomIn = () => setZoom(zoom * 1.5);
  const handleZoomOut = () => zoom >= 1.5 && setZoom(zoom / 1.5);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const regionResponse = await API.get(`/project-modules`, {
          headers: { 'Authorization': 'Bearer ' + token },
        });
        const regionCategories = regionResponse.data.filter(item => item.category === 'region');
        setRegionData(regionCategories);
        setRegionParentData(regionCategories);

        const moduleResponse = await API.get(`/project-modules?module_id=${id}`, {
          headers: { 'Authorization': 'Bearer ' + token },
        });
        const subModuleResponse = await API.get(`/get-sub-modules?module_id=${id}`, {
          headers: { 'Authorization': 'Bearer ' + token },
        });
        // const mapResponse = await API.get(`/map?region=${id}`, {
        //   headers: { 'Authorization': 'Bearer ' + token },
        // });
        // setCountryData(mapResponse.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id, token]);

  const modifiedCountryData = countryData && countryData.filter((item)=>item.parent_id==null||item.parent_id=="")

  const highlightedCountries = useMemo(
    () => modifiedCountryData?.map(item => ({ code: item.country_code, value: item.value, metric_type:item.metric__datatype })) || [],
    [countryData]
  );

  const isHighlighted = (countryCode) => highlightedCountries.some(country => country.code === countryCode);

  const handleMouseMove = (e) => setPosition({ x: e.clientX, y: e.clientY });

  const handleMouseEnter = (countryProperties) => {
    setShowTooltip(true);
    const countryName = countryProperties.ADMIN;
    const countryValue = countryProperties.value;
    const content = `${countryName}: ${countryValue || '0'}`;
    setTooltipContent(content);
  };

  const handleMouseLeave = () => setShowTooltip(false);

  const handleCountryClick = (countryProperties) => {
    const countryCode = countryProperties.ISO_A2;
    const countryName = countryProperties.ADMIN;
    const country = highlightedCountries.find(country => country.code === countryCode);
    alert(`You clicked on ${countryName} and ${country?.value || '0'}`);
  };


  const [isStateLevel, setIsStateLevel] = useState(false);
  const [isStateLevel1sttime, setIsStateLevel1sttime] = useState(1);

  const handleToggle = () => {
    setIsStateLevel(!isStateLevel);
   if(isStateLevel){ setIsStateLevel1sttime(0)}
  };

useEffect(()=>{
 if(isStateLevel==false && isStateLevel1sttime==0) {
  setIsLoading(true)
  setTimeout(() => {
    setIsLoading(false)
    setIsStateLevel1sttime(1)
  }, 5000);}
},[isStateLevel])


    
    const handleChangeMetric = async (event) => {
        if (!event) {
            setCountryData([]);
            return;
        }
        setIsLoading(true);
        setSelectedMetricValue(event.value);
        try {
              const response = await API.get(`/map`, {
                headers: { 'Authorization': 'Bearer ' + token },
                params: {
                    pillar_id: pillarID,
                    region: id,
                    ...(selectionType === 'year' && { fiscal_year: selectedYearOption1 }),
                    ...(selectionType === 'qtr' && { fiscal_qtr: selectedQtrOption1 }),
                    metric_id: event.value
                }
            });
            setCountryData(response.data);
        } catch (error) {
            console.error("Error fetching map data:", error);
        } finally {
            setIsLoading(false);
        }
    };
console.log("option",selectionType,selectedYearOption1,selectedQtrOption1,selectedQtrOption)
    const handleChangeYear = async (event) => {
      if (!event) {
        
      setMetric([])
          setCountryData([]);
          if (metricSelectRef.current) {
            metricSelectRef.current.clearValue();
          }
          return;
      }
      
      setSelectedYearOption(null)
      setSelectedQtrOption(null)
      setSelectedQtrOption1(null)
      setMetric([])
      setIsLoading(true);
      setSelectionType(event.name);
      const [prefix, value] = event.value.split('_');
      try {
        if (prefix === 'year') {
          setSelectedYearOption(event.value);
          setSelectedYearOption1(value);
          const metricResponse = await API.get(`/metric-period`, {
            headers: { 'Authorization': 'Bearer ' + token },
            params: {pillar_id: pillarID ,fiscal_year: value }
          })
          setMetric(metricResponse.data);
        }else if (prefix === 'qtr'){  
        setSelectedQtrOption1(value)
          const metricResponse = await API.get(`/metric-period`, {
            headers: { 'Authorization': 'Bearer ' + token },
            params: { pillar_id: pillarID , fiscal_qtr: value }
          })
          setMetric(metricResponse.data);
        }
      } catch (error) {
          console.error("Error fetching map data:", error);
      } finally {
          setIsLoading(false);
        }
  };
  const handleChangePillar = async (event) => {
    if (event===null) {
      if (yearSelectRef.current) {
        yearSelectRef.current.clearValue();
      }
      setCountryData([]);
      setSelectedYearOption(null)
      setSelectedQtrOption(null)
      setFiscalYearList([]);
      setFiscalQtrList([]);
      return;
    }
    setIsLoading(true);
    try {
      if (yearSelectRef.current) {
        yearSelectRef.current.clearValue();
      }
      setPillarID(event.value);
    } catch (error) {
      console.error("Error fetching map data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  console.log("pillarevent", );
 const yearSelectRef = useRef(null);
  const metricSelectRef = useRef(null);
  const pillarSelectRef = useRef(null);

  useEffect(() => {
    if (metricSelectRef.current) {
      metricSelectRef.current.clearValue();
    }
    if (yearSelectRef.current) {
      yearSelectRef.current.clearValue();
    }
    if (pillarSelectRef.current) {
      pillarSelectRef.current.clearValue();
    }
  }, [id]);
  useEffect(() => {
    if (metricSelectRef.current) {
      metricSelectRef.current.clearValue();
    }
  }, [selectedYearOption,selectedQtrOption, metric ]);

    const flattenedOptions = useMemo(() => {
      return fiscalYearList.slice().reverse().reduce((acc, year) => {
        acc.push({ value: `year_${year.id}`, label: year.name, name: 'year' });
        const quarterOptions = fiscalQtrList
          .filter(quarter => quarter.name.includes(year.name.slice(-2))) // Matching fiscal year and quarters
          .map(quarter => ({
            value: `qtr_${quarter.id}`,
            label: quarter.name,
            name: 'qtr'
          }));
        return acc.concat(quarterOptions);
      }, []);
    }, [fiscalYearList, fiscalQtrList]);



    const sortedOptions = [...flattenedOptions].sort((a, b) => {
      // Sort by fiscal year (FY20, FY21, etc.)
      const yearA = a.label.match(/FY(\d+)/);
      const yearB = b.label.match(/FY(\d+)/);
      
      // Extract fiscal year numbers for comparison
      const yearNumA = yearA ? parseInt(yearA[1], 10) : 0;
      const yearNumB = yearB ? parseInt(yearB[1], 10) : 0;
  
      // If both are in the same fiscal year
      if (yearNumA === yearNumB) {
          // Prioritize year over quarters
          if (a.name === 'year' && b.name === 'qtr') return -1;
          if (a.name === 'qtr' && b.name === 'year') return 1;
  
          // Sort quarters in descending order (Q4, Q3, Q2, Q1)
          const quarterA = a.label.match(/Q(\d+)/);
          const quarterB = b.label.match(/Q(\d+)/);
  
          if (quarterA && quarterB) {
              return parseInt(quarterB[1], 10) - parseInt(quarterA[1], 10);
          }
      }
  
      // Sort fiscal years in descending order
      return yearNumB - yearNumA;
  });
  

  const options = metric.map(m => ({
    value: m.metric,
    label: m.metric_name
}));

const Pillarsoptions = sIOPillars.map(m => ({
  value: m.id,
  label: m.name
}));


    useEffect(() => { 
        setMetric([])
        const fetchData = async () => {
          setIsLoading(true);
            try {
              
                const projectModulesResponse = await API.get('/project-modules', {
                    headers: { 'Authorization': 'Bearer ' + token }
                });
                const regionCategories = projectModulesResponse.data.filter(item => item.category === 'region');
                setRegionData(regionCategories);
                setRegionParentData(regionCategories);

                // const pillarResponse = await API.get(`/pillar?module_id=${id}`, {
                //     headers: { 'Authorization': 'Bearer ' + token }
                // });
                // setPillarID(pillarResponse.data.id);
                const SIIOPillarResponse = await API.get(`/sio-pillar`, {
                  headers: { 'Authorization': 'Bearer ' + token }
              });
              setSIOPillars(SIIOPillarResponse?.data);
                const fiscalYearResponse = await API.get(`/fiscal-year-detail`, {
                  headers: { 'Authorization': 'Bearer ' + token }
                });
                setFiscalYearList(fiscalYearResponse.data[0].fiscalyear);
                setFiscalQtrList(fiscalYearResponse.data[1].fiscalquarter);
                
      setIsLoading(false);
    } catch (error) {
              setIsLoading(false);
              console.error("Error fetching initial data:", error);
            }
          };

        fetchData();
    }, [pillarID,id, token]);
  return (
    <>
     {isLoading && <FullScreenLoader/>}
      <Heading as='h3' size='lg' mb='25px' fontSize='19px' mt='20px' className={Styles.customHeadingH3}>
        Geographic Impact
      </Heading>
      <LevelSwitch isStateLevel={isStateLevel} handleToggle={handleToggle} setIsStateLevel={setIsStateLevel}/>
      
      <Card backgroundColor='rgb(201 210 211)' p='0px'>
        <CardBody p='0px'>
          <Box className={Styles.zoomControls}>
            <button className={Styles.zoomIn} onClick={handleZoomIn}>
              <AddIcon w={3} h={3} />
            </button>
            <button className={Styles.zoomOut} onClick={handleZoomOut}>
              <MinusIcon w={3} h={3} />
            </button>
          </Box>
          <Box className={Styles.filterDropDown}>
          <Select
               ref={pillarSelectRef}
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={handleChangePillar}
                options={Pillarsoptions}
                placeholder="Select Pillar"
                isClearable
                />
              <Select
               ref={yearSelectRef}
                styles={customStyles}
                className={`${Styles.marginRight} ${Styles.selectBox}`}
                onChange={handleChangeYear}
                options={sortedOptions}
                placeholder="Select Year"
                isClearable
                />
              <Select
              ref={metricSelectRef}
              className={Styles.selectBox}
                styles={customStyles}
                onChange={handleChangeMetric}
                options={options}
                placeholder="Select Metric"
                isClearable
              />
            </Box>
           
          <Box className={Styles.mapContainer} style={{overflow:"hidden"}}>
          {isStateLevel===false ? (
            <ComposableMap
              style={{ width: '100%', height: 'auto' }}
              projection="geoMercator"
              projectionConfig={{
                scale: defaultScale * zoom,
                center: [10, 10], // Adjust center to ensure Norway is visible
              }}
            >
              <ZoomableGroup>
                <Geographies geography={worldData}>
                  {({ geographies }) =>
                    geographies.filter(geo => geo.properties.ADMIN !== "Antarctica").map((geo) => {
                      const countryCode = geo.properties.ISO_A2;
                      const fillColor = isHighlighted(countryCode) ? '#00a0da' : '#ffffff';
                      const countryValue = highlightedCountries.find(country => country.code === countryCode)?.value;
                      const Mtericdatatype = highlightedCountries.find(
                        (country) => country.code === countryCode
                      )?.metric_type;
                      const newValue = formatNumber(countryValue || 0);
                       const regionMatch = regionData.find(region => region.Parent_id === countryCode);

                      const regionId = regionMatch?.id || '';
                      const regionParentId = regionMatch?.parent_id || '';
                      const regionMatchParent = regionParentData.find(region => region.id === regionParentId);
                      const regionParentCode = regionMatchParent?.name || '';

                      const isSelected = isHighlighted(countryCode);
                      const strokeColor = isSelected ? '#000000' : '#D6D6DA';
                      const strokeWidth = 0.5;

                      return (
                        <Link to={`/region/${regionParentId}/${regionParentCode}/country/${regionId}/${geo.properties.ADMIN}`} key={geo.rsmKey}>
                          <Tooltip
                            overflow="none"
                            background="#2b2b2b"
                            color="#fff"
                            fontSize="14px"
                            boxShadow="none"
                            label={` ${geo.properties.ADMIN} ${
                              newValue !== 0
                                ? `: ${Mtericdatatype ? (getMetricdatatypeSymbol(Mtericdatatype) === "%" ? `${newValue}${getMetricdatatypeSymbol(Mtericdatatype)}` : `${getMetricdatatypeSymbol(Mtericdatatype)} ${newValue}`) : ""}`
                                : ""
                            }`}    // label={`${geo.properties.ADMIN}${newValue !=0 ? ":":""} ${newValue !=0 ? newValue:""}`}
                          >
                            <Geography
                              key={geo.rsmKey}
                              geography={geo}
                              fill={fillColor}
                              stroke="f7f7f7"
                              strokeWidth={0}
                              // style={{
                              //   default: { fill: fillColor, outline: 'none' },
                              //   // hover: { fill: '#D2D2D2', outline: 'none', filter: "drop-shadow(0px 1px 1px #2c2c2c50)" },
                              // }}
                              onClick={() => handleCountryClick(geo.properties)}
                            />
                          </Tooltip>
                        </Link>
                      );
                    })
                  }
                </Geographies>
              </ZoomableGroup>
            </ComposableMap>
             ):
             <>
            <MapFOrUSstate countryData={countryData} zoom={zoom} setIsLoading={setIsLoading}/>
             </>}
          </Box>
        </CardBody>
      </Card>
    </>
  );
};

export default RegionMap;

export function getMetricdatatypeSymbol(caseNumber) {
  switch (caseNumber) {
      case "money":
          return '$';
      case "percent":
          return '%';
      case "number":
          return '#';
      
  }
}


const LevelSwitch = ({isStateLevel,handleToggle,setIsStateLevel}) => {


  return (
    <FormControl display="flex" alignItems="center">
      <Text mr={2} mb={2} color={isStateLevel ? "blue.500" : "gray.500"}>
        State Level
      </Text>
      <Switch
        id="level-switch"
        isChecked={!isStateLevel}
        onChange={handleToggle}
        colorScheme="blue"
        size="sm"
      />
      <Text mb={2}  ml={2} color={!isStateLevel ? "blue.500" : "gray.500"}>
        Country Level
      </Text>
    </FormControl>
  );
};

