import React, { useState, useRef, useMemo, useContext } from 'react';
import { useParams } from 'react-router-dom'
import $ from 'jquery'
import Styles from './form.module.css'
import API from '../../../../Services/API'
import {Input, Stack, Button, Spinner, Image, } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const Form = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(false)
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState(false)      
    const editor = useRef(null);
    const {id} = useParams();

    const { projectModules } = useContext(EditContext);
    console.log("proprs",projectModules)
    function bannerUpload(e) {
        setFile(e.target.files[0]);
    }

    const updateAboutSIoInfo = (event) =>{
        setIsLoading(true)
        setMessage(false)
        if(props.count && props.count > 0){
            const formData = {
            //   name: $("#pagetitle").val(),
              description: $("#description").val(),
              id: props.info && props.info.id,
            };
            API.put(`/training`, formData, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                API.get(`/training`, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                })
                .then((response) => {
                    setMessage(true)
                    props.getUpdateAbout(response)
                }).catch(error=>{
                    setIsLoading(false)
                })
                const colletralId = projectModules.find(
                    (a) => a?.unique_name === "tguides"
                  );
                const formData1 =  new FormData()
                formData1.append('id', colletralId && colletralId?.id)
                // formData1.append('name', $("#pagetitle").val())
                API.put(`/project-modules`, formData1, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    setIsLoading(false)
                    // window.location.reload()
                }).catch(error=>{
                    setIsLoading(false)
                })
            }).catch(error=>{
                setIsLoading(false)
            })
        }else{
            const formData = {
                // name: $("#pagetitle").val(),
                description: $("#description").val(),
              };
            API.post(`/training`, formData, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                API.get(`/training`, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                })
                .then((response) => {
                    props.getUpdateAbout(response)
                }).catch(error=>{
                    setIsLoading(false)
                })
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }
    }
    
    return (
        <>
            { 
                isLoading ? <LoaderSpinner />: null
            }
               { message ? <ErrorWidget message={"Training Guides / Videos updated successfully "}/> : null}
        
            <Stack spacing={3} mb={'30px'}>
                {/* <Input
                    id={"pagetitle"} 
                    placeholder='Please Enter Title' 
                    size='md' 
                    backgroundColor={"#ffffff"} 
                    defaultValue={props.info && props.info.name}
                /> */}
                <JoditEditor
                    id={"description"}
                    ref={editor}
                    value={props.info && props.info.description}
                    tabIndex={1} // tabIndex of textarea
                    onChange={newContent => {}}
                />
                <Button className='primaryButtonNew' w={100} onClick={updateAboutSIoInfo}>
                    Save
                </Button>

                
            </Stack>
        </>
    )
}

export default Form

