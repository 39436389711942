import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../metricdata.module.css'
import API from '../../../../Services/API';
import { IconButton } from '@chakra-ui/react'
import { EditIcon, DeleteIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Card,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Checkbox, 
    Stack,
    Textarea,
    Text,
    Button,
    Tooltip
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../Components/Widgets/ErrorWidget/ErrorWidget';
const DeleteComponent = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [metricData, setMetricData] = useState()
    const [isLoading, setIsLoading] = useState()
    const [message, setMessage] = useState(null)

    const deleteMetric = (event) => {
        onClose()
        setIsLoading(true)
        setMessage(null)
        const body = {
          "id": parseInt(event.target.id),
          "visible": false,
        }
        API.put(`/metric-data`,body, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          setMessage("Metric deleted successfully")
          API.get(`/my-task`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }).then(response=>{
            const sortedMetrics = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            props.getUpdatedPending(response.data)
            setIsLoading(false)
          }).catch(error=>{
            setIsLoading(false)
        })
        }).catch(error=>{
          setMessage("Metric not deleted successfully")
          setIsLoading(false)
      })
    }

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            {
              message ?<ErrorWidget message={message} /> : null
            }
            <Tooltip id={props.id} label="Delete metric data" aria-label="Delete metric data"> 
              <IconButton
                variant='solid'
                aria-label='Done'
                fontSize='15px'
                icon={<DeleteIcon />}
                ml={'5px'}
                className={Styles.edit} id={props.id}
                onClick={onOpen}
              />
            </Tooltip>
            
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex}>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text className={Styles.contentInfo}>
                          Are you sure you want to delete it? This action cannot be reversed.
                        </Text>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button className={Styles.confirmButton} id={props.id} onClick={deleteMetric}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeleteComponent