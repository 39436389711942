import React, { useEffect, useState } from 'react';
import Styles from './Aspirations.module.css'
import { Box, Text, Spinner, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import API from '../../../Services/API';
import { TabContainer } from 'react-bootstrap';
import EditMetric from './EditMetric/EditMetric';
import DeleteComponent from './DeleteComponent/DeleteComponent'
import ApproveComponent from './ApproveComponent/ApproveComponent';
import RejectComponent from './RejectComponent/RejectComponent';

const Metrics = () => {
    const token = window.localStorage.getItem("accessToken")
    const [metrics, setMetrics] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        API.get(`/mytask-metric`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const sortedMetrics = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            // console.log("mytask",sortedMetrics)
            setMetrics(sortedMetrics);
            setLoading(false);
        }).catch(error=>{
        })
    }, []);

    const getMetricInfo = () =>{
        API.get(`/mytask-metric`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            const sortedMetrics = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
            setMetrics(sortedMetrics);
            setLoading(false);
        }).catch(error=>{
            
        })
    }

    return (
        <>
            <TabContainer>
                <Table>
                    <Thead>
                        <Tr bg="gray.100">
                            <Th className={Styles.th} color="blue.600">Name</Th>
                            <Th className={Styles.th} color="blue.600">SIIO</Th>
                            <Th className={Styles.th} color="blue.600">Action</Th>
                            <Th className={Styles.th} color="blue.600">Type</Th>
                            <Th className={Styles.th} color="blue.600">strategy</Th>
                            <Th className={Styles.th} color="blue.600">Approval</Th>
                            <Th className={Styles.th} color="blue.600">Approve</Th>
                            {/* <Th className={Styles.th} color="blue.600">Action</Th> */}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            metrics && metrics.map((metric, index) =>
                                <Tr className={Styles.tr}>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {metric.name}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>{metric.pillar_name} </Box>
                                    </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> 
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>{metric.action_name}</Box>
                                    </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {metric.metrictype} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {metric.strategy_name} </Td>
                                    <Td className={Styles.td} whiteSpace="nowrap"> {metric.approval_status} </Td>
                                    <Td className={Styles.td+' actionButton'} textAlign={'center'}> 
                                        <ApproveComponent id={metric.id} getMetricInfo={getMetricInfo} />
                                        <RejectComponent id={metric.id} getMetricInfo={getMetricInfo} />
                                    </Td>
                                    {/* <Td className={Styles.td+' actionButton'} textAlign={'center'}>
                                        <EditMetric metric={metric} getMetricInfo={getMetricInfo} />
                                        <DeleteComponent id={metric.id} getMetricInfo={getMetricInfo} />
                                    </Td> */}
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TabContainer>
        </>
    )
}

export default Metrics