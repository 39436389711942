import React from 'react';
import ReactPlayer from 'react-player';

const YouTubePlayer = ({ videoUrl }) => {
  if (!videoUrl) {
    return <p>Please provide a valid URL.</p>;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0',height:"100vh" }}>
       <iframe
        src={videoUrl}
        controls
        width="100%"
        height="100%"
        allowFullScreen
        style={{ borderRadius: '8px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
      />
    </div>
  );
};

export default YouTubePlayer;
